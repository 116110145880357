import React, { useState, useEffect, useCallback } from "react";
import {
  TooltipBlock,
  TooltipColumn,
  TooltipContainer,
  TooltipHeading,
  TooltipLabel,
  TooltipValue,
} from "./styles";

export const Tooltip = ({
  x,
  y,
  yAxe,
  xAxe,
  data,
  children,
  name,
  matrix,
}: {
  name?: string;
  x: number;
  y: number;
  yAxe: string;
  xAxe: string;
  data: { [key: string]: string };
  children?: any;
  allLables?: any[];
  matrix?: boolean;
}) => {
  const [position, setPosition] = useState({ top: y, left: x });
  const [trianglePosition, setTrianglePosition] = useState({
    left: "25px",
    transform: "translateX(-50%)",
  });

  const tooltipWidth = 150;
  const tooltipHeight = 100;
  const padding = 5;

  const calcPointerPosition = useCallback(() => {
    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    let newX = x + scrollX;
    let newY = y + scrollY;
    let isAtEdge = false;

    if (newX + tooltipWidth > window.innerWidth + scrollX) {
      newX = window.innerWidth + scrollX - tooltipWidth - padding;
      isAtEdge = true;
    } else if (newX < padding + scrollX) {
      newX = padding + scrollX;
      isAtEdge = true;
    }

    if (newY + tooltipHeight > window.innerHeight + scrollY) {
      newY = y - padding - tooltipHeight / 10;
    }

    if (isAtEdge) {
      const triangleOffsetX = x - newX + 22;
      setTrianglePosition({
        left: `${triangleOffsetX}px`,
        transform: "translateX(0)",
      });
    } else {
      setTrianglePosition({
        left: "25px",
        transform: "translateX(-50%)",
      });
    }
    setPosition({ top: newY, left: newX });
  }, [x, y, tooltipWidth, tooltipHeight, padding]);

  useEffect(() => {
    calcPointerPosition();
  }, [calcPointerPosition]);

  const xAxeLabel = xAxe?.charAt(0)?.toUpperCase() + xAxe?.slice(1);
  const existLabels = Object.keys(data);
  const otherLabels = existLabels?.filter((r) => r !== xAxe && r !== yAxe);
  const groubLabels = otherLabels?.map((r) => r?.replace("_", " "));

  const renderValue = () => {
    if (yAxe === "value") {
      const value = parseFloat(data[yAxe]);
      const areZecimale = value % 1 !== 0;

      if (areZecimale) {
        return value?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return value?.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return data[yAxe];
  };

  const key = matrix ? data[yAxe] : data[xAxeLabel.toLowerCase()];

  return (
    <TooltipContainer
      style={{ top: position.top, left: position.left }}
      $triangleLeft={trianglePosition.left}
      $triangleTransform={trianglePosition.transform}
    >
      <TooltipBlock>
        {(!!name || !!key) && (
          <TooltipColumn>
            <TooltipHeading>{name || key}</TooltipHeading>
          </TooltipColumn>
        )}
        {yAxe === "value" && (
          <TooltipColumn $border>
            <TooltipLabel>Members</TooltipLabel>
            <TooltipValue>{renderValue()}</TooltipValue>
          </TooltipColumn>
        )}

        {!((data[xAxe] === key || data[xAxe] === name) && !!!Number(key)) && (
          <TooltipColumn $border>
            <TooltipLabel>{xAxeLabel}</TooltipLabel>
            <TooltipValue>{data[xAxe]}</TooltipValue>
          </TooltipColumn>
        )}

        {otherLabels?.length
          ? otherLabels?.map((l, i) => {
            return data[l] === key || data[l] === name ? null : (
              <TooltipColumn key={l} $border>
                <TooltipLabel>{groubLabels[i]}</TooltipLabel>
                <TooltipValue>{data[l]}</TooltipValue>
              </TooltipColumn>
            );
          })
          : null}
        {children}
      </TooltipBlock>
    </TooltipContainer>
  );
};
