import styled from "styled-components";

import { OverviewContent } from "../styles";
import { CloseIcon } from "../ProjectSettings/styles";

import { Pencil, CheckCircleFill, XLg } from "react-bootstrap-icons";

export const ModalSubmenu = styled.div`
  display: flex;
  gap: 16px;
  height: 36px;
  border-bottom: 1px solid #d3dbe3;
  padding: 0 20px 0 20px;
`;

export const CloseModal = styled(CloseIcon)``;

export const PencilIcon = styled(Pencil)`
  cursor: pointer;
  fill: ${({theme}) => theme.neutralTextWeak};
  width: 20px;
  height: 20px;
 
  &:hover {
    fill: ${({theme}) => theme.neutralText};
  }

  &:active {
    fill: ${({theme}) => theme.neutralText};
  }
`

export const SaveIcon = styled(CheckCircleFill)`
  cursor: pointer;
  fill: ${({ theme }) => theme.primaryBackgroundStrong};
  width: 20px;
  height: 20px;

  &:hover {
    fill: ${({theme}) => theme.primaryTextHover};
  }
  
  &:active {
    fill: ${({theme}) => theme.primaryTextHover};
  }
`
export const CancelIcon = styled(XLg)`
  cursor: pointer;
  fill: ${({theme}) => theme.neutralTextWeak};
  width: 20px;
  height: 20px;

  &:hover {
    fill: ${({theme}) => theme.neutralText};
  }
  
  &:active {
    fill: ${({theme}) => theme.neutralText};
  }
`

export const NameButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 10px;
`

export const HeadingNameSpan = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const HeadingNameInput = styled.input`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
  border: none;
  outline: none;
  min-width: 40px;
  max-width: 540px;

  &:not(:disabled) {
    border-right: 1px solid ${({ theme }) => theme.primaryText};
    background-color: ${({ theme }) => theme.primaryBackground};
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
`

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;

  &:hover {
    ${HeadingNameInput} {
      background-color: ${({ theme }) => theme.neutralBackgroundHover};
      border-radius: 2px;
    }
  }
`

export const ModalHeadingWrapperExt = styled.div`
  padding-bottom: 0;
  display: block;
  height: auto;
  position: relative;
`;

export const SubmenuItem = styled.div<{ $selected: boolean }>`
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
 
  color: ${({ theme, $selected }) =>
    $selected ? theme.neutralText : theme.neutralTextWeak};
  &::after{
    content: '';
    width: 100%;
    position: absolute;
    bottom: -1px;
    border-bottom: 2px solid transparent;
    border-bottom-color: ${({ theme, $selected }) =>
    $selected ? theme.primaryBorder : "transparent"};
  }
  z-index: 1;
`;

export const SubmenuTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

export const OverviewContentExt = styled(OverviewContent)`
  height: calc(100% - 140px);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;
