import { StoreInterface } from "../../models";

export const getAiSuggestions = (store: StoreInterface) =>
  store.widgets.aiSuggestions;

export const getAiKpis = (store: StoreInterface) => store.widgets.aiKpis;

export const getStorytellingSuggestions = (store: StoreInterface) =>
  store.widgets.storytellingSuggestions;

export const getExploreData = (store: StoreInterface) =>
  store.widgets.exploreData;