import styled, { css } from "styled-components";
import {
  backDropFadeIn,
  backDropFadeOut,
  commonSeparatorStyle,
  fadeInRight,
  fadeOutRight,
} from "../../styles";
import { ChevronLeft, XLg } from "react-bootstrap-icons";

const fileOverviewStyles = css`
  left: 16px;
  right: 16px;
`;
const projectSettingsStyles = css`
  max-width: 480px;
  right: 16px;
`;

const recommendedWidgetsStyles = css`
  max-width: 672px;
  right: 16px;
  width: 100%;
`;
const exploreMapStyles = css`
  max-width: 704px;
  right: 16px;
  width: 100%;
`;

const modalWrapperDefault = css`
  right: 16px;
  max-width: 480px;
  width: 100%;
`;

export const ModalWrapper = styled.div<{
  $isClosing?: boolean;
  $fileOverviewOpen?: boolean;
  $projectSettingsOpen?: boolean;
  $recommendedWidgetsStyles?: boolean;
  $exploreMap?: boolean;
}>`
  background-color: ${({ theme }) => theme.neutralBackgroundBase};
  height: 96vh;
  position: absolute;
  border: 1px solid ${({ theme }) => theme.neutralBorder};
  box-shadow: 0 0 50px 10px rgba(99, 99, 99, 0.2);
  border-radius: 12px;
  opacity: ${({ $isClosing }) => ($isClosing ? 1 : 0)};
  ${({ $fileOverviewOpen, $projectSettingsOpen, $recommendedWidgetsStyles, $exploreMap }) =>
    $fileOverviewOpen
      ? fileOverviewStyles
      : $projectSettingsOpen
        ? projectSettingsStyles
        : $recommendedWidgetsStyles
          ? recommendedWidgetsStyles
          : $exploreMap
            ? exploreMapStyles
            : modalWrapperDefault}
  animation: 0.4s
  ${({ $isClosing }) => ($isClosing ? fadeOutRight : fadeInRight)} ease
  forwards;
`;

export const OverviewContent = styled.div<{ $isLoading?: boolean }>`
  display: flex;
  position: relative;
  height: calc(100% - 128px);
`;

export const ModalOverlay = styled.div<{
  $isClosing?: boolean;
  $noBlur?: boolean;
}>`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1;
  background: ${({ theme }) => theme.neutralBackgroundActive};
  backdrop-filter: ${({ $noBlur }) => ($noBlur ? "" : "blur(1px)")};
  animation: ${({ $isClosing }) =>
    $isClosing ? backDropFadeOut : backDropFadeIn}
    0.3s ease-in-out;
  opacity: ${({ $isClosing }) => ($isClosing ? 0 : 1)};
  pointer-events: ${({ $isClosing }) => ($isClosing ? "none" : "auto")};
  z-index: 10;
`;

export const ModalHeadingWrapper = styled.div<{
  $fileOverviewOpen?: boolean;
  $disableSeparator?: boolean;
}>`
  max-width: ${({ $fileOverviewOpen }) => $fileOverviewOpen && "480px"};
  position: relative;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;
  &:before {
      bottom: 0;
      display: ${({ $disableSeparator }) => $disableSeparator === false ? 'block' : 'none'};
      ${commonSeparatorStyle};
    }
  
`;

export const ModalHeading = styled.h2`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.neutralText};
`;

export const CloseIcon = styled(XLg)`
  cursor: pointer;
  filter: invert(56%) sepia(23%) saturate(181%) hue-rotate(179deg)
    brightness(89%) contrast(86%);
  margin-left: 16px;
`;

export const ModalContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const CloseIconWrap = styled.div`
  margin-left: auto;
`;

export const ModalContent = styled.div<{ $fileOverviewOpen?: boolean }>`
  display: block;
  overflow: auto;
  width: 100%;
  ${({ $fileOverviewOpen }) =>
    $fileOverviewOpen &&
    `
    border-right: 1px solid #D3DBE3;
    max-width: 480px;
    flex: 2;
    `};
`;

export const ModalFooterWrapper = styled.div<{
  $fileOverviewOpen?: boolean;
  $flexEnd?: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: ${({ $flexEnd }) =>
    $flexEnd ? "flex-end" : "space-between"};
  align-items: end;
  padding: 12px 15px;
  max-width: ${({ $fileOverviewOpen }) => $fileOverviewOpen && "480px"};
  box-shadow: ${({ theme }) => theme.boxShadowColor};

  &:after {
    top: 0;
    ${commonSeparatorStyle};
  }
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;
`;

export const BackIcon = styled(ChevronLeft)`
  cursor: pointer;
`;

export const ModalFooterButtons = styled.div`
  position: relative;
  display: flex;
  gap: 16px;
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;
