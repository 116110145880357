import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import Map, { Source, Layer, FillLayer } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";

import { MapLegend } from "../../MapLegend";
import { setActiveModal } from "../../../store/slices/modals";
import { getPageSettings } from "../../../store/selectors/projects";
import { colorsPalettes } from "../../../constants";
import { MapYear } from "../../MapYear";
import {
  MarkersVisualisationDataDto,
  WidgetItem,
} from "../../../models/Widgets";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import { ColorRangeI } from "../../../models/Pages";
import { Loader } from "../../Loader";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import dataCounties from "./Config/GeoJson/us-counties.json";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  SettingsButtonWrapper,
} from "../VerticalBarchart/styles";
import {
  DisableBuildingsWrapper,
  LoaderWrapper,
  MapHeader,
  MapWrapper,
  SelectBageBlock,
} from "./styles";
import { getLayerStrokeStyle, getLayerStyle } from "./utils/getLayerStyle";
import { getIDofCounty } from "./utils/getIdOfCounty";
import { hexToRGBA } from "../../../helpers/hexToRgba";
import { generateBreakPoints } from "../../../helpers/generateBreakPoints";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import { SelectBage } from "../SelectBage";
import { calculateValuesAndSum } from "./utils/calculateValuesAndSum";
import { getYears } from "./utils/getYears";
import { getLayerColor } from "./utils/getLayerColor";
import { getGeoJsonData } from "./utils/getGeoJsonData";
import { Popup } from "./components/Popup";
import { getCountyDataFromId } from "./utils/getCountyDataFromId";
import { getValueWithDecimals } from "./utils/getValueWithDecimals";
import SwitchComponent from "../../Inputs/CustomSwitch/Switch";
import { changeLayerVisibility } from "./utils/disableLayer";
import useMeasure from "react-use-measure";

interface MapChartInterface {
  showSettings?: boolean;
  showLegend?: boolean;
  recommended?: boolean;
  selected?: boolean;
  isFullScreen?: boolean;
  currentWidget: WidgetItem;
  explore?: boolean;
  exploreYear?: number;
  exploreCounty?: string;
  exploreLat?: number;
  exploreLng?: number;
  exploreCountyId?: string;
}

export const UniversalMap = ({
  showSettings = true,
  currentWidget,
  recommended,
  selected,
  explore,
  exploreYear,
  exploreCounty,
  exploreLat,
  exploreLng,
  exploreCountyId,
}: MapChartInterface) => {
  const dispatch = useDispatch();
  const mapRef = useRef<any>();
  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const [ref, bounds] = useMeasure();

  const colorPalette = colorsPalettes.find(
    (palette) => palette.id === (styleId || "default")
  );
  const colorPaletteVariations = colorPalette?.colors[0].variations;

  const [layerStyle, setLayerStyle] = useState<FillLayer>(getLayerStyle([]));
  const [layerData, setLayerData] = useState<MarkersVisualisationDataDto[]>([]);
  const [years, setYears] = useState<number[]>([]);
  const [year, setYear] = useState<number | undefined>();
  const [average, setAverage] = useState<number | undefined>();
  const [colorRanges, setColorRanges] = useState<ColorRangeI[]>([]);
  const [popupData, setPopupData] = useState<{
    x: number;
    y: number;
    countyStateCode?: string;
    year?: string;
    value?: string;
    zip?: string;
    latitude?: string;
    longitude?: string;
  } | null>(null);
  const [selectedCounty, setSelectedCounty] = useState<string | null>(null);
  const [values, setValues] = useState<number[]>([]);
  const [leftSlider, setLeftSlider] = useState(0);
  const [rightSlider, setRightSlider] = useState(6);
  const [disableBuildings, setDisableBuildings] = useState<boolean>(false);

  const usCounties: GeoJSON.GeoJsonProperties = dataCounties;

  const id = useMemo(
    () => (recommended ? "recommended_map" : explore ? "explore_map" : "map"),
    [recommended, explore]
  );

  const generateColorRanges = useCallback(() => {
    if (colorPaletteVariations?.length && values.length) {
      const colors = colorPaletteVariations.slice().reverse();
      const sortedValues = [...new Set(values)].sort();
      const steps = generateBreakPoints(sortedValues);
      const newColorRanges = steps?.map((c, index) => {
        const end = index === steps.length - 1 ? c * 3 : steps[index + 1];
        return {
          color:
            index >= leftSlider && index <= rightSlider
              ? colors[index]
              : "#E0DDDC",
          start: parseInt(c.toString()),
          end: parseInt(end.toString()),
        };
      });
      setColorRanges(newColorRanges);
    }
  }, [colorPaletteVariations, values, leftSlider, rightSlider]);

  useEffect(() => {
    if (mapRef?.current) {
      const mapInstance = mapRef?.current;
      mapInstance.resize();
    }
  }, [bounds.height, bounds.width]);

  useEffect(() => {
    if (colorPaletteVariations?.length) generateColorRanges();
  }, [colorPaletteVariations, values, generateColorRanges]);

  useEffect(() => {
    if (currentWidget) {
      const valueKey = currentWidget?.layers?.at(0)?.arrangeByMetric?.at(0);
      const layerData = currentWidget?.layers?.at(0)?.data || [];
      const { values, sum } = calculateValuesAndSum(layerData, valueKey);
      setValues(values);
      setAverage(sum / (layerData.length || 1));
      setLayerData(layerData);
      getYears(layerData, setYear, setYears);
    }
  }, [currentWidget]);

  const geoJsonData: GeoJSON.FeatureCollection<GeoJSON.Geometry> = useMemo(
    () => getGeoJsonData(usCounties),
    [usCounties]
  );

  const clearPopupData = () => {
    setSelectedCounty(null);
    setPopupData(null);
  };
  useEffect(() => {
    if (explore && geoJsonData) {
      setSelectedCounty(exploreCountyId || "");
    }
  }, [geoJsonData.features, explore, exploreCountyId, geoJsonData]);

  useEffect(() => {
    const filteredData = layerData?.filter(
      (d) =>
        parseInt(d.year) === (exploreYear || year) &&
        (exploreCounty ? d.county === exploreCounty : true)
    );
    if (filteredData && colorRanges.length) {
      const styles = filteredData
        .map((county) => {
          const id = getIDofCounty(county);
          const color = getLayerColor(colorRanges, county);
          return [id, hexToRGBA(color, 1)];
        })
        .flat();
      setLayerStyle(getLayerStyle(styles));
    }
  }, [year, layerData, explore, colorRanges, exploreYear, exploreCounty]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();
    if (map && !explore) {
      map.on("load", () => {
        changeLayerVisibility("building (1)", map);
        changeLayerVisibility("country-label", map);
      });
      const onMouseEnter = (e: any) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["county-fill-layer"],
        });
        if (features.length) {
          const feature = features[0];
          const countyPropreties = getCountyDataFromId(
            feature.properties.county
          );
          const [county, state] =
            countyPropreties.COUNTY_STATE_NAME.split(", ");
          const countyData = layerData.find(
            (el) => el.county === county && el.state === state
          );

          // if (popupData && countyData && selectedCounty !== feature.properties.county) {
          //   clearPopupData()
          // }

          if (!countyData) {
            setSelectedCounty(null);
            return;
          }

          setSelectedCounty(feature.properties.county);
        }
      };

      const onMouseLeave = () => {
        if (!popupData || !Object.keys(popupData).length) {
          clearPopupData();
        }
      };

      const onMouseClick = (e: any) => {
        const features = map.queryRenderedFeatures(e.point, {
          layers: ["county-fill-layer"],
        });
        if (features.length) {
          const feature = features[0];
          const countyPropreties = getCountyDataFromId(
            feature.properties.county
          );

          if (
            popupData &&
            popupData.countyStateCode === countyPropreties.COUNTY_STATE_CODE
          ) {
            return setPopupData(null);
          }

          const [county, state] =
            countyPropreties.COUNTY_STATE_NAME.split(", ");
          const countyData = layerData.find(
            (el) =>
              el.county === county &&
              el.state === state &&
              el.year === (year || "2017")
          );
          if (!countyData) {
            clearPopupData();
            return;
          }

          const coordinates = e.point;
          setSelectedCounty(feature.properties.county);
          const latLong = feature.geometry.coordinates[0];
          const [longitude, latitude] = latLong[0];
          setPopupData({
            zip: feature.properties.county,
            x: coordinates.x,
            y: coordinates.y,
            latitude: latitude,
            longitude: longitude,
            countyStateCode: countyPropreties.COUNTY_STATE_CODE,
            value: getValueWithDecimals(countyData),
            year: countyData.year,
          });
        }
      };

      map.on("mousemove", "county-fill-layer", onMouseEnter);
      map.on("click", "county-fill-layer", onMouseClick);
      map.on("mouseleave", "county-fill-layer", onMouseLeave);

      return () => {
        map.off("mousemove", "county-fill-layer", onMouseEnter);
        map.on("click", "county-fill-layer", onMouseClick);
        map.off("mouseleave", "county-fill-layer", onMouseLeave);
      };
    }
  }, [mapRef, layerData, year, popupData, explore]);

  useEffect(() => {
    const map = mapRef?.current?.getMap();
    if (map && map.isStyleLoaded()) {
      if (!disableBuildings) {
        changeLayerVisibility("building (1)", map);
      }
      if (disableBuildings) {
        changeLayerVisibility("building (1)", map, "visible");
      }
    }
  }, [disableBuildings, mapRef]);

  const isCard = currentWidget.layout === "card";
  return (
    <>
      <MapWrapper ref={ref} $explore={explore}>
        {!explore && isCard && <MapHeader>Map</MapHeader>}
        <Map
          id={id}
          ref={mapRef}
          initialViewState={{
            latitude: exploreLat || 35,
            longitude: exploreLng || -120,
            zoom: explore ? 5 : 4,
          }}
          style={{
            position: "absolute",
            width: explore ? "100%" : `calc(100% - ${isCard ? "32px" : "0"})`,
            height: explore
              ? "100%"
              : `calc(100% - ${
                  isCard
                    ? recommended || !currentWidget.timeline
                      ? "56px"
                      : "114px"
                    : "0"
                })`,
            top: 0,
            left: 0,
            margin: explore
              ? "0"
              : `${
                  isCard
                    ? recommended || !currentWidget.timeline
                      ? "40px 16px 16px"
                      : "40px 16px 74px"
                    : "0px"
                }`,
            borderRadius: explore ? 0 : "6px",
            border: `1px solid rgb(211,219,227)`,
          }}
          mapStyle={
            currentWidget.terrain
              ? "mapbox://styles/andyk1987/clnefgdzy01ze01qne2766ihi"
              : "mapbox://styles/andyk1987/cm1kvt8ib00ie01pi1lty0ey4"
          }
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
        >
          <Source id="counties" type="geojson" data={geoJsonData}>
            <Layer beforeId="road" {...layerStyle} />
          </Source>
          <Source id="counties" type="geojson" data={geoJsonData}>
            <Layer
              beforeId="road"
              {...getLayerStrokeStyle(selectedCounty, !!showTooltip)}
            />
          </Source>
        </Map>
      </MapWrapper>
      {!!layerData?.length ? (
        <>
          {!isPublicRoute && isEditMode && !recommended && !explore ? (
            <SettingsButtonWrapper
              $modalOpen={!showSettings}
              $map
              onClick={() => {
                dispatch(setCurrentWidget(currentWidget!));
                dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
              }}
            >
              <DatavizRecommendedCount>
                {AVAILABLE_WIDGETS["mapChart"]?.length}
              </DatavizRecommendedCount>
              <DatavizSettingsIcon />
            </SettingsButtonWrapper>
          ) : null}
          {!recommended && !explore && (
            <DisableBuildingsWrapper
              $timeline={!!years?.length && currentWidget.timeline}
              $isCard={isCard}
            >
              <SwitchComponent
                label={!disableBuildings ? "2D" : "3D"}
                onChange={() => setDisableBuildings(!disableBuildings)}
                values={disableBuildings}
              />
            </DisableBuildingsWrapper>
          )}

          {recommended ? (
            <SelectBageBlock>
              <SelectBage selected={!!selected} />
            </SelectBageBlock>
          ) : null}
          {years?.length &&
          currentWidget.timeline &&
          !recommended &&
          !explore ? (
            <MapYear
              years={years}
              setYear={setYear}
              selectedYear={year!}
              isCard={isCard}
            />
          ) : null}
          {showTooltip && popupData && mapRef.current?.getContainer() && (
            <Popup
              popupData={popupData}
              mapContainer={mapRef.current.getContainer()}
              clearPopupData={clearPopupData}
            />
          )}

          {!recommended && !explore && (
            <MapLegend
              colorRanges={colorRanges}
              average={average!}
              leftSlider={leftSlider}
              rightSlider={rightSlider}
              setLeftSlider={setLeftSlider}
              setRightSlider={setRightSlider}
              isCard={isCard}
              timeline={currentWidget.timeline!}
            />
          )}
        </>
      ) : (
        <LoaderWrapper>
          <Loader blur={false} />
        </LoaderWrapper>
      )}
    </>
  );
};
