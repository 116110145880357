export const SIMPLE_WIDGETS = [
  "barChart",
  "lineChart",
  "areaChart",
  "lollipopChart",
  "matrixChart",
  "matrix",
  "scatterplot",
  "scatterplotChart",
  "radarChart",
  "radar",
  "polarAreaChart",
  "bubbleChart",
];

export const COMPLEX_WIDGETS = [
  "mapChart",
  "sankey",
  "sankeyChart",
  "punchcardChart",
];

export const ALL_WIDGETS = [
  "barChart",
  "lineChart",
  "areaChart",
  "lollipopChart",
  "matrixChart",
  "matrix",
  "mapChart",
  "sankey",
  "sankeyChart",
  "scatterplot",
  "scatterplotChart",
  "radarChart",
  "radar",
  "punchcardChart",
  "polarAreaChart",
  "bubbleChart",
];

export const LAYOUTS = [
  {
    id: "1_1_a",
    complexity: 1,
    numWidgets: 1,
    variant: "a",
    image: "../../layouts/1_1_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "100%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_2_a",
    complexity: 1,
    numWidgets: 2,
    variant: "a",
    image: "../../layouts/1_2_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "100%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_2_b",
    complexity: 1,
    numWidgets: 2,
    variant: "b",
    image: "../../layouts/1_2_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "100%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_3_a",
    complexity: 1,
    numWidgets: 3,
    variant: "a",
    image: "../../layouts/1_3_a.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "20%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "80%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_3_b",
    complexity: 1,
    numWidgets: 3,
    variant: "b",
    image: "../../layouts/1_3_b.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "20%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "40%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "40%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_3_c",
    complexity: 1,
    numWidgets: 3,
    variant: "c",
    image: "../../layouts/1_3_c.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "50%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "50%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_4_a",
    complexity: 1,
    numWidgets: 4,
    variant: "a",
    image: "../../layouts/1_4_a.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "20%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "80%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      rows: [
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 3,
                              widgets: ALL_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 4,
                              widgets: ALL_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_4_b",
    complexity: 1,
    numWidgets: 4,
    variant: "b",
    image: "../../layouts/1_4_b.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "20%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "40%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "40%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_4_c",
    complexity: 1,
    numWidgets: 4,
    variant: "c",
    image: "../../layouts/1_4_c.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "50%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "50%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_5_a",
    complexity: 1,
    numWidgets: 5,
    variant: "a",
    image: "../../layouts/1_5_a.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "20%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "40%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          height: "40%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 5,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_5_b",
    complexity: 1,
    numWidgets: 5,
    variant: "b",
    image: "../../layouts/1_5_b.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "20%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "40%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "40%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "60%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "40%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_5_c",
    complexity: 1,
    numWidgets: 5,
    variant: "c",
    image: "../../layouts/1_5_c.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "50%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 2,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 3,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "50%",
          columns: [
            {
              size: 6,
              blockId: 4,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 5,
              widgets: ALL_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_6_a",
    complexity: 1,
    numWidgets: 6,
    variant: "a",
    image: "../../layouts/1_6_a.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: ALL_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: ALL_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_6_b",
    complexity: 1,
    numWidgets: 6,
    variant: "b",
    image: "../../layouts/1_6_b.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 1,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 2,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 3,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 4,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 5,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 6,
              widgets: ALL_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_6_c",
    complexity: 1,
    numWidgets: 6,
    variant: "c",
    image: "../../layouts/1_6_c.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "50%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 2,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 3,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "50%",
          columns: [
            {
              size: 4,
              blockId: 4,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 5,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 6,
              widgets: ALL_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_7_a",
    complexity: 1,
    numWidgets: 7,
    variant: "a",
    image: "../../layouts/1_7_a.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 4,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 4,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 4,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 7,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_7_b",
    complexity: 1,
    numWidgets: 7,
    variant: "b",
    image: "../../layouts/1_7_b.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 2,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 3,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 4,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 5,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 6,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 7,
              widgets: ALL_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_7_c",
    complexity: 1,
    numWidgets: 7,
    variant: "c",
    image: "../../layouts/1_7_c.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 7,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_8_a",
    complexity: 1,
    numWidgets: 8,
    variant: "a",
    image: "../../layouts/1_8_a.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 4,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 4,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 4,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 7,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 8,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_8_b",
    complexity: 1,
    numWidgets: 8,
    variant: "b",
    image: "../../layouts/1_8_b.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              blockId: 1,
              size: 6,
              widgets: ALL_WIDGETS,
            },
            {
              blockId: 2,
              size: 6,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 4,
              blockId: 3,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 4,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 5,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 4,
              blockId: 6,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 7,
              widgets: ALL_WIDGETS,
            },
            {
              size: 4,
              blockId: 8,
              widgets: ALL_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "1_8_c",
    complexity: 1,
    numWidgets: 8,
    variant: "c",
    image: "../../layouts/1_8_c.jpg",
    admisibleWidgets: ALL_WIDGETS,
    numComplexWidgets: 0,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 3,
              blockId: 1,
              widgets: ALL_WIDGETS,
            },
            {
              size: 3,
              blockId: 2,
              widgets: ALL_WIDGETS,
            },
            {
              size: 3,
              blockId: 3,
              widgets: ALL_WIDGETS,
            },
            {
              size: 3,
              blockId: 4,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 5,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 6,
              widgets: ALL_WIDGETS,
            },
          ],
        },
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 7,
              widgets: ALL_WIDGETS,
            },
            {
              size: 6,
              blockId: 8,
              widgets: ALL_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_1_a",
    complexity: 2,
    numWidgets: 1,
    variant: "a",
    image: "../../layouts/2_1_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "100%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  height: "100%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: ALL_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_2_a",
    complexity: 2,
    numWidgets: 2,
    variant: "a",
    image: "../../layouts/2_2_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          height: "100%",
          columns: [
            {
              size: 12,
              rows: [
                {
                  columns: [
                    {
                      size: 6,
                      rows: [
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                    {
                      size: 6,
                      rows: [
                        {
                          height: "100%",
                          columns: [
                            {
                              size: 12,
                              blockId: 2,
                              widgets: COMPLEX_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_2_c",
    complexity: 2,
    numWidgets: 2,
    variant: "c",
    image: "../../layouts/2_2_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "100%",
                  columns: [
                    {
                      size: 12,
                      hasPadding: true,
                      rows: [
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 2,
              full: true,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_3_a",
    complexity: 2,
    numWidgets: 3,
    variant: "a",
    image: "../../layouts/2_3_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: [],
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 4,
              rows: [
                {
                  height: "20%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "80%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 8,
              rows: [
                {
                  height: "100%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_3_b",
    complexity: 2,
    numWidgets: 3,
    variant: "b",
    image: "../../layouts/2_3_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 8,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "66.6666%",
          columns: [
            {
              size: 12,
              blockId: 3,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_3_c",
    complexity: 2,
    numWidgets: 3,
    variant: "c",
    image: "../../layouts/2_3_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              hasPadding: true,
              rows: [
                {
                  height: "20%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 3,
              full: true,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_4_a",
    complexity: 2,
    numWidgets: 4,
    variant: "a",
    image: "../../layouts/2_4_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 4,
              rows: [
                {
                  height: "20%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "40%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "40%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 8,
              rows: [
                {
                  height: "100%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_4_b",
    complexity: 2,
    numWidgets: 4,
    variant: "b",
    image: "../../layouts/2_4_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "66.6666%",
          columns: [
            {
              size: 12,
              blockId: 4,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_4_c",
    complexity: 2,
    numWidgets: 4,
    variant: "c",
    image: "../../layouts/2_4_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "20%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "40%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "40%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 4,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_5_a",
    complexity: 2,
    numWidgets: 5,
    variant: "a",
    image: "../../layouts/2_5_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 4,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 8,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "66.6666%",
          columns: [
            {
              size: 4,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 8,
              blockId: 5,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_5_b",
    complexity: 2,
    numWidgets: 5,
    variant: "b",
    image: "../../layouts/2_5_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              height: "100%",
              size: 6,
              blockId: 5,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_5_c",
    complexity: 2,
    numWidgets: 5,
    variant: "c",
    image: "../../layouts/2_5_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      hasPadding: true,
                      rows: [
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 5,
              full: true,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_6_a",
    complexity: 2,
    numWidgets: 6,
    variant: "a",
    image: "../../layouts/2_6_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "66.6666%",
          columns: [
            {
              size: 4,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 8,
              blockId: 6,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_6_b",
    complexity: 2,
    numWidgets: 6,
    variant: "b",
    image: "../../layouts/2_6_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 12,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  height: "66.6666%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "66.6666%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_6_c",
    complexity: 2,
    numWidgets: 6,
    variant: "c",
    image: "../../layouts/2_6_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              hasPadding: true,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 6,
              full: true,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_7_a",
    complexity: 2,
    numWidgets: 7,
    variant: "a",
    image: "../../layouts/2_7_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 6,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 6,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "66.6666%",
          columns: [
            {
              size: 6,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 6,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 6,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 6,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 7,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_7_b",
    complexity: 2,
    numWidgets: 7,
    variant: "b",
    image: "../../layouts/2_7_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 3,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 4,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "66.6666%",
          columns: [
            {
              size: 6,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 12,
                              blockId: 6,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 7,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_7_c",
    complexity: 2,
    numWidgets: 7,
    variant: "c",
    image: "../../layouts/2_7_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              scroll: true,
              hasPadding: true,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 6,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 7,
              full: true,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_8_a",
    complexity: 2,
    numWidgets: 8,
    variant: "a",
    image: "../../layouts/2_8_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "33.3333%",
          columns: [
            {
              size: 3,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 6,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "66.6666%",
          columns: [
            {
              size: 6,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 6,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "50%",
                          columns: [
                            {
                              size: 6,
                              blockId: 6,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 7,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 8,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_8_b",
    complexity: 2,
    numWidgets: 8,
    variant: "b",
    image: "../../layouts/2_8_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 3,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 7,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "67%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 3,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 8,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "2_8_c",
    complexity: 2,
    numWidgets: 8,
    variant: "c",
    image: "../../layouts/2_8_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 1,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              scroll: true,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 6,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 6,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 7,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 9,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 10,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 11,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 12,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 13,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                        {
                          height: "33.3333%",
                          columns: [
                            {
                              size: 12,
                              blockId: 14,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 8,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_2_a",
    complexity: 3,
    numWidgets: 2,
    variant: "a",
    image: "../../layouts/3_2_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "100%",
          columns: [
            {
              size: 6,
              blockId: 1,
              widgets: [...SIMPLE_WIDGETS, "sankey", "sankeyChart"],
            },
            {
              size: 6,
              blockId: 2,
              widgets: ["mapChart"],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_2_c",
    complexity: 3,
    numWidgets: 2,
    variant: "c",
    image: "../../layouts/3_2_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "100%",
          columns: [
            {
              size: 6,
              hasPadding: true,
              rows: [
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: [...SIMPLE_WIDGETS, "sankey", "sankeyChart"],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 2,
              full: true,
              widgets: ["mapChart"],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_3_a",
    complexity: 3,
    numWidgets: 3,
    variant: "a",
    image: "../../layouts/3_3_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "20%",
          columns: [
            {
              size: 12,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "80%",
          columns: [
            {
              size: 6,
              blockId: 2,
              widgets: COMPLEX_WIDGETS,
            },
            {
              size: 6,
              blockId: 3,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_3_b",
    complexity: 3,
    numWidgets: 3,
    variant: "b",
    image: "../../layouts/3_3_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "20%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "80%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              height: "100%",
              size: 6,
              blockId: 3,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_3_c",
    complexity: 3,
    numWidgets: 3,
    variant: "c",
    image: "../../layouts/3_3_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "20%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "80%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 3,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_4_a",
    complexity: 3,
    numWidgets: 4,
    variant: "a",
    image: "../../layouts/3_4_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "40%",
          columns: [
            {
              size: 3,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 9,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "60%",
          columns: [
            {
              size: 6,
              blockId: 3,
              widgets: COMPLEX_WIDGETS,
            },
            {
              size: 6,
              blockId: 4,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_4_b",
    complexity: 3,
    numWidgets: 4,
    variant: "b",
    image: "../../layouts/3_4_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "40%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "60%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              height: "100%",
              size: 6,
              blockId: 4,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_4_c",
    complexity: 3,
    numWidgets: 4,
    variant: "c",
    image: "../../layouts/3_4_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "40%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "60%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 4,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_5_a",
    complexity: 3,
    numWidgets: 5,
    variant: "a",
    image: "../../layouts/3_5_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 4,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 8,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_5_b",
    complexity: 3,
    numWidgets: 5,
    variant: "b",
    image: "../../layouts/3_5_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "40%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "60%",
          columns: [
            {
              size: 6,
              blockId: 4,
              widgets: COMPLEX_WIDGETS,
            },
            {
              size: 6,
              blockId: 5,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_5_c",
    complexity: 3,
    numWidgets: 5,
    variant: "c",
    image: "../../layouts/3_5_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              scroll: true,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 5,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_6_a",
    complexity: 3,
    numWidgets: 6,
    variant: "a",
    image: "../../layouts/3_6_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_6_b",
    complexity: 3,
    numWidgets: 6,
    variant: "b",
    image: "../../layouts/3_6_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "40%",
          columns: [
            {
              size: 3,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 4,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "60%",
          columns: [
            {
              size: 6,
              blockId: 5,
              widgets: COMPLEX_WIDGETS,
            },
            {
              size: 6,
              blockId: 6,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_6_c",
    complexity: 3,
    numWidgets: 6,
    variant: "c",
    image: "../../layouts/3_6_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              scroll: true,
              hasPadding: true,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: ["sankey", "sankeyChart"],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 6,
              full: true,
              widgets: ["mapChart"],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_7_a",
    complexity: 3,
    numWidgets: 7,
    variant: "a",
    image: "../../layouts/3_7_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 6,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 7,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_7_b",
    complexity: 3,
    numWidgets: 7,
    variant: "b",
    image: "../../layouts/3_7_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "20%",
          columns: [
            {
              size: 3,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 6,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 3,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "30%",
          columns: [
            {
              size: 6,
              blockId: 4,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 6,
              blockId: 5,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "50%",
          columns: [
            {
              size: 6,
              blockId: 6,
              widgets: COMPLEX_WIDGETS,
            },
            {
              size: 6,
              blockId: 7,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_7_c",
    complexity: 3,
    numWidgets: 7,
    variant: "c",
    image: "../../layouts/3_7_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              scroll: true,
              hasPadding: true,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: ["sankey", "sankeyChart"],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 7,
              full: true,
              widgets: ["mapChart"],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_8_a",
    complexity: 3,
    numWidgets: 8,
    variant: "a",
    image: "../../layouts/3_8_a.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 6,
                              blockId: 1,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 2,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 6,
                              blockId: 3,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 4,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      rows: [
                        {
                          columns: [
                            {
                              size: 6,
                              blockId: 5,
                              widgets: SIMPLE_WIDGETS,
                            },
                            {
                              size: 6,
                              blockId: 6,
                              widgets: SIMPLE_WIDGETS,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              rows: [
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 7,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
                {
                  height: "50%",
                  columns: [
                    {
                      size: 12,
                      blockId: 8,
                      widgets: COMPLEX_WIDGETS,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_8_b",
    complexity: 3,
    numWidgets: 8,
    variant: "b",
    image: "../../layouts/3_8_b.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          height: "25%",
          columns: [
            {
              size: 4,
              blockId: 1,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 2,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 3,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "25%",
          columns: [
            {
              size: 4,
              blockId: 4,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 5,
              widgets: SIMPLE_WIDGETS,
            },
            {
              size: 4,
              blockId: 6,
              widgets: SIMPLE_WIDGETS,
            },
          ],
        },
        {
          height: "50%",
          columns: [
            {
              size: 6,
              blockId: 7,
              widgets: COMPLEX_WIDGETS,
            },
            {
              size: 6,
              blockId: 8,
              widgets: COMPLEX_WIDGETS,
            },
          ],
        },
      ],
    },
  },
  {
    id: "3_8_c",
    complexity: 3,
    numWidgets: 8,
    variant: "c",
    image: "../../layouts/3_8_c.jpg",
    admisibleWidgets: SIMPLE_WIDGETS,
    numComplexWidgets: 2,
    complexWidgets: COMPLEX_WIDGETS,
    arranging: {
      rows: [
        {
          columns: [
            {
              size: 6,
              scroll: true,
              hasPadding: true,
              rows: [
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 6,
                      blockId: 1,
                      widgets: SIMPLE_WIDGETS,
                    },
                    {
                      size: 6,
                      blockId: 2,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 3,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 4,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 5,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 6,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 7,
                      widgets: ["sankey", "sankeyChart"],
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 9,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 10,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 11,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 12,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 13,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 14,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
                {
                  height: "33.3333%",
                  columns: [
                    {
                      size: 12,
                      blockId: 15,
                      widgets: SIMPLE_WIDGETS,
                    },
                  ],
                },
              ],
            },
            {
              size: 6,
              blockId: 8,
              full: true,
              widgets: ["mapChart"],
            },
          ],
        },
      ],
    },
  },
];
