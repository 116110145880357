import React, { useEffect, useMemo, useState } from "react";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import useMeasure from "react-use-measure";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  getIsEditMode,
  getIsPublicMode,
} from "../../../../store/selectors/main";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../../store/selectors/projects";
import { getAiSuggestions } from "../../../../store/selectors/widgets";
import { AiSuggestionsDto, WidgetItem } from "../../../../models/Widgets";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../../VerticalBarchart/styles";
import { ChartLegend, ChartLegendValue } from "../../../ChartLegend";
import { setActiveModal } from "../../../../store/slices/modals";
import { hexToRGBA } from "../../../../helpers/hexToRgba";
import { calculateNumTicks } from "../../widgetHelpers";
import { ticksFormatter } from "../../../../helpers/ticksFormatter";
import { Tooltip } from "../../Tooltip";
import { Loader } from "../../../Loader";
import { setCurrentWidget } from "../../../../store/slices/projectPages";
import { SelectBage } from "../../SelectBage";
import { replaceWords } from "../../../../helpers/replaceName";
import { LollipopMarkersVertical } from "../../utils/getMarker";
import {
  get_data,
  get_xAxe,
  get_yAxe,
  getGroupedData,
} from "../utils/getLollipopChartMarkers";
import {
  getCurrentColor,
  getCurrentMarker,
} from "../../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../../constants/widgetRecomended";
import { getClientPosition } from "../../components/LabelTooltip";
import { createPortal } from "react-dom";
import { getPosition } from "../utils/getLollipopAlign";
import { HeadingNameAndButton } from "../../styles";
import { getActiveModal } from "../../../../store/selectors/modals";

interface LollipopInterface {
  storytelling?: boolean;
  recommended?: boolean;
  showLegend?: boolean;
  selected?: boolean;
  currentWidget: WidgetItem;
  hideName?: boolean;
  hideSettings?: boolean;
}

export const VerticalLollipopChart = ({
  storytelling,
  recommended,
  showLegend = true,
  selected = false,
  currentWidget,
  hideName = false,
  hideSettings = false,
}: LollipopInterface) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const [groupedData, setGroupedData] = useState<{
    [keey: string]: { x: number; y: number }[];
  }>({});
  const [legendValues, setLegendValues] = useState<ChartLegendValue[]>([]);
  const [data, setData] = useState<{ x: number; y: number }[]>([]);
  const [yAxes, setYAxes] = useState([]);
  const [xAxe, setXAxe] = useState<string>();
  const [yAxe, setYAxe] = useState<string>();
  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);
  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const margin = { top: 10, bottom: 25, left: 40, right: 3 };
  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const xScale = scaleBand({
    range: [margin.left, width - margin.right],
    domain: data?.map((d: any) => d.x),
    padding: 0,
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...yAxes)],
    range: [height - margin.bottom, margin.top],
    nice: true,
  });

  useEffect(() => {
    const lollipopChartSuggestion = aiSuggestions?.filter(
      (chart: AiSuggestionsDto) => chart.chartType === "lollipopChart"
    )[0];

    if (currentWidget) {
      const xAxe = get_xAxe(currentWidget);
      setXAxe(xAxe);

      const yAxe = get_yAxe(currentWidget);
      setYAxe(yAxe);

      const data = get_data(currentWidget) ?? [];
      setData(data);

      const yAxes =
        data?.reduce((t: any, l: any) => {
          const y = parseFloat(l.y);
          if (typeof y === "number" && !isNaN(y)) {
            return [...t, y];
          }
          return t;
        }, []) || [];
      setYAxes(yAxes);

      let groupedData: any = getGroupedData(
        currentWidget,
        lollipopChartSuggestion
      );

      const newLegendValues = [];

      const groupBy = currentWidget?.groupBy?.at(0);

      const uniqueValuesKeys =
        (currentWidget?.uniqueValues &&
          Object.keys(currentWidget?.uniqueValues!)) ||
        [];
      const groupByKey =
        groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

      let chartGroupKeys =
        uniqueValuesKeys?.length && currentWidget?.uniqueValues
          ? currentWidget?.uniqueValues[groupByKey!]
          : [];

      if (!chartGroupKeys?.length && groupedData) {
        chartGroupKeys = Object.keys(groupedData);
      }

      if (groupedData && chartGroupKeys?.length) {
        for (let i = 0; i < chartGroupKeys?.length; i++) {
          const dataKey = chartGroupKeys?.at(i);
          const color = getCurrentColor(currentWidget, dataKey, styleId);
          newLegendValues.push({ label: dataKey!, color });
        }
      }

      setLegendValues(newLegendValues);
      setGroupedData(groupedData);
    }
  }, [aiSuggestions, currentWidget, styleId]);

  const numTicks = useMemo(
    () => calculateNumTicks({ height: height }),
    [height]
  );

  const name = useMemo(() => {
    return recommended
      ? replaceWords(currentWidget?.name)
      : currentWidget?.name;
  }, [currentWidget?.name, recommended]);

  if (_.isEmpty(data)) {
    return (
      <>
        <div style={{ height: "100%", width: "100%" }}>
          <Loader blur={false} />
        </div>
      </>
    );
  }

  const chartValues =
    (currentWidget &&
      currentWidget.formatting &&
      currentWidget.formatting.length) ??
    1;
  return (
    <>
      <HeaderWrapper ref={refWidget}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!hideSettings && !isPublicRoute && !recommended && isEditMode ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["lollipopChart"]?.length}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}

        {legendValues?.length > 1 &&
          chartValues > 1 &&
          showLegend &&
          currentWidget.legend && (
            <ChartLegend
              chartWidth={boundsWidget.width}
              legendType="unit"
              legendValues={legendValues}
            />
          )}
      </HeaderWrapper>
      <svg width="100%" height={"100%"} ref={ref}>
        <Group top={0} left={0}>
          <Group>
            {xScale.domain().map((value, index) => (
              <line
                key={`${value}-${index}`}
                x1={xScale(value)}
                x2={xScale(value)}
                y1={margin.top}
                y2={height - margin.bottom}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            ))}
          </Group>
          <Group>
            {yScale.ticks(numTicks).map((value, index) => {
              return (
                <line
                  key={`${value}-${index}`}
                  x1={margin.left}
                  y1={yScale(value)}
                  x2={width - margin.right}
                  y2={yScale(value)}
                  stroke="#ccc"
                  strokeDasharray="1 2"
                />
              );
            })}
            <line
              x1={width - margin.right}
              y1={margin.top}
              x2={width - margin.right}
              y2={height - margin.bottom}
              stroke="#e0e0e0"
              strokeDasharray="1 2"
            />
          </Group>
          <Group>
            {groupedData &&
              Object.keys(groupedData)?.map((key: string, val: number) => {
                const groupData = groupedData[key] as any;
                const xVal = getPosition(val, chartValues);

                return groupData?.map((d: any, index: number) => {
                  const color = getCurrentColor(currentWidget, key, styleId);
                  const barKey = `${d.x}-${index}-${val}`;

                  return (
                    <g
                      key={barKey}
                      style={{ transition: "0.3s" }}
                      onMouseMove={(e: any) => {
                        if (
                          (showTooltip || currentWidget.tooltip) &&
                          !recommended
                        ) {
                          const { x, y } = getClientPosition(e);
                          setHoveredElement(barKey);
                          setTooltip({
                            name: key !== "default" ? key : undefined,
                            data: {
                              [xAxe as string]: d.x,
                              [yAxe as string]: d.y,
                            },
                            x: x - 27,
                            y: y - 85,
                          });
                        }
                      }}
                      onMouseLeave={() => {
                        setTooltip(null);
                        setHoveredElement(null);
                      }}
                      opacity={
                        hoveredElement
                          ? hoveredElement === barKey
                            ? 1
                            : 0.2
                          : 1
                      }
                    >
                      <line
                        x1={xScale(d.x)! + xVal + xScale.bandwidth() / 2}
                        y1={yScale(d.y) + 2}
                        x2={xScale(d.x)! + xVal + xScale.bandwidth() / 2}
                        y2={height - margin.bottom}
                        stroke={hexToRGBA(color!, 0.5)}
                        strokeWidth={3}
                      />
                      {!!color &&
                        LollipopMarkersVertical({
                          markerType: getCurrentMarker(
                            currentWidget,
                            key,
                            "rhombus"
                          ),
                          xScale: xScale(d.x)!,
                          xVal: xVal,
                          xScaleBand: xScale.bandwidth() / 2,
                          color: color,
                          yScale: yScale(d.y),
                        })}
                    </g>
                  );
                });
              })}
          </Group>
          <AxisBottom
            top={height - margin.bottom}
            scale={xScale}
            hideTicks
            tickLabelProps={{
              fontSize: 11,
              fill: "#5F6877",
              dy: -2,
              dx: 0,
            }}
            axisLineClassName="barchartAxisLine"
          />
          {numTicks ? (
            <AxisLeft
              left={margin.left}
              scale={yScale}
              hideAxisLine
              numTicks={numTicks}
              label="Value"
              tickLineProps={{
                stroke: "#939BA7",
              }}
              tickLabelProps={() => ({
                fontSize: 11,
                fill: "#5F6877",
                textAnchor: "start",
                dy: 4,
                dx: -30,
              })}
              tickFormat={(value: any) => {
                return ticksFormatter(value);
              }}
            />
          ) : null}
        </Group>
      </svg>
      {tooltip &&
        xAxe &&
        yAxe &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}
    </>
  );
};
