import { call, all } from "redux-saga/effects";

import { watchAuthActions } from "./authSaga";
import { watchProjectActions } from "./projectsSaga";
import { watchFileActions } from "./filesSaga";
import { watchDatasetActions } from "./datasetSaga";
import { watchProjectPagesSaga } from "./pagesSaga";
import { watchWidgetsSaga } from "./widgetsSaga";
import { watchProfileActions } from "./profilesSaga";

export function* rootSaga() {
  yield all([
    call(watchProjectPagesSaga),
    call(watchProfileActions),
    call(watchProjectActions),
    call(watchDatasetActions),
    call(watchAuthActions),
    call(watchFileActions),
    call(watchWidgetsSaga),
  ]);
}
