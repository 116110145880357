import React, { useEffect, useMemo, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { ModalOverlay } from "../styles";
import {
  UpdateModalContentWrapper,
  UpdateModalHeaderWrapper,
  UpdateModalWrapper,
  AccountBlock,
  AccountAvatar,
  AccountFullName,
  UpdateTab,
  UpdateTabs,
  UpdateModalFooterWrapper,
} from "./styles";
import { AccountDto } from "../../../models/Authentication";
import { getUser } from "../../../store/selectors/main";
import { Button } from "../../Button";
import { CheckCircle } from "react-bootstrap-icons";
import { Essentials } from "./Essentials";
import { ContactDetails } from "./ContactDetails";
import { Security } from "./Security";
import { Permissions } from "./Permissions";
import { Notifications } from "./Notifications";
import { getProfiles } from "../../../store/selectors/profiles";
import { IProfile } from "../../../models/Widgets";
import { requestUpdateProfile } from "../../../store/slices/profiles";
import _ from "lodash";

const formInitialValues: IProfile = {
  id: "",
  uid: "",
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  jobTitle: "",
  organization: "",
  location: "",
  avatar: "",
  officePhone: "",
  isAdmin: false,
  canAddProjects: false,
  autoAccessToFutureProjects: false,
  desktopNotifications: {
    importantAlerts: false,
    dashboardPublished: false,
    viewerEditor: false,
    playSoundOnReminder: false,
    statusUpdate: false,
  },
  emailNotifications: {
    emailViewerEditor: false,
    emailDashboardCompleted: false,
    emailStatusUpdate: false,
  },
};

export const profileValidationSchema = () => {
  return Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your First Name")
      .matches(/^[a-zA-Z]+$/, "First Name should only contain letters"),

    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Last Name")
      .matches(/^[a-zA-Z]+$/, "Last Name should only contain letters"),

    jobTitle: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Job Title")
      .matches(/^[a-zA-Z]+$/, "Job Title should only contain letters"),

    organization: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Organization"),

    location: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Please enter your Location"),
  });
};

export const UpdateProfileModal = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const currentProfile = useSelector(getProfiles);
  const [account, setAccount] = useState<AccountDto | undefined>();
  const [selectedTab, setTab] = useState<string | undefined>("essentials");

  const [profileSettings] = useState<IProfile>({
    ...formInitialValues,
    ...currentProfile,
  });

  useEffect(() => {
    if (user?.accessToken) {
      const account: AccountDto = jwtDecode(user?.accessToken);
      setAccount(account);
    }
  }, [user]);

  const fullName = useMemo(() => {
    const name = [profileSettings.firstName, profileSettings.lastName]
      ?.join(" ")
      ?.trim();
    if (name?.length) {
      return name;
    }
    return "Robert Robertson";
  }, [profileSettings?.firstName, profileSettings?.lastName]);

  const initials = useMemo(() => {
    let initials = profileSettings?.email?.charAt(0);
    if (
      profileSettings?.firstName?.length ||
      profileSettings?.lastName?.length
    ) {
      initials =
        (profileSettings?.firstName?.charAt(0) || "") +
        (profileSettings?.lastName?.charAt(0) || "");
    }
    return initials?.toUpperCase();
  }, [
    profileSettings?.email,
    profileSettings?.firstName,
    profileSettings?.lastName,
  ]);

  const handleSubmitForm = (values: IProfile) => {
    dispatch(
      requestUpdateProfile({
        ...values,
        callbacks: {
          onSuccess: () => {
            onClose();
          },
        },
      })
    );
  };

  return (
    <ModalOverlay onClick={onClose}>
      <UpdateModalWrapper onClick={(e) => e.stopPropagation()}>
        <UpdateModalHeaderWrapper>
          <AccountBlock>
            <AccountAvatar>{initials}</AccountAvatar>
            <AccountFullName>{fullName}</AccountFullName>
          </AccountBlock>
          <UpdateTabs>
            <UpdateTab
              $selected={selectedTab === "essentials"}
              onClick={() => setTab("essentials")}
            >
              Essentials
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "contact_details"}
              onClick={() => setTab("contact_details")}
            >
              Contact details
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "security"}
              onClick={() => setTab("security")}
            >
              Security
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "permissions"}
              onClick={() => setTab("permissions")}
            >
              Permissions
            </UpdateTab>
            <UpdateTab
              $selected={selectedTab === "notifications"}
              onClick={() => setTab("notifications")}
            >
              Notifications
            </UpdateTab>
          </UpdateTabs>
        </UpdateModalHeaderWrapper>
        <Formik
          initialValues={profileSettings}
          validationSchema={profileValidationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmitForm}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            return (
              <>
                <UpdateModalContentWrapper>
                  <Form onSubmit={handleSubmit}>
                    {selectedTab === "essentials" && (
                      <Essentials
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        initials={initials}
                        avatar={account?.avatar}
                      />
                    )}
                    {selectedTab === "contact_details" && (
                      <ContactDetails
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {selectedTab === "security" && (
                      <Security
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {selectedTab === "permissions" && (
                      <Permissions
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                    {selectedTab === "notifications" && (
                      <Notifications
                        setFieldValue={setFieldValue}
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                      />
                    )}
                  </Form>
                </UpdateModalContentWrapper>
                <UpdateModalFooterWrapper>
                  <Button
                    name="Cancel"
                    onClick={onClose}
                    variant="neutral"
                    size="medium"
                  />
                  {selectedTab === "security" ? (
                    <Button
                      name="Change Password"
                      onClick={() => {}}
                      disabled={false}
                      variant={"secondary"}
                      size="medium"
                      icon={<CheckCircle />}
                    />
                  ) : (
                    <Button
                      name="Update"
                      onClick={handleSubmit}
                      disabled={_.isEqual(values, profileSettings)}
                      variant={
                        _.isEqual(values, profileSettings)
                          ? "disabled"
                          : "secondary"
                      }
                      size="medium"
                      icon={<CheckCircle />}
                    />
                  )}
                </UpdateModalFooterWrapper>
              </>
            );
          }}
        </Formik>
      </UpdateModalWrapper>
    </ModalOverlay>
  );
};
