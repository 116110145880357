import { IFormatting } from "../../../../models/Widgets";

export const getPolarAreaChartDefaultColors = (data: any): IFormatting[] => {
  const uniqueValuesKey = Object.keys(data.uniqueValues || {})?.[0];
  const keys = data.uniqueValues[uniqueValuesKey] || [];

  return keys.map((item: string, index: number) => ({
    key: item,
    color: String(index + 1),
  }));
};
