import {
  AiKpiDto,
  CreateAndUpdateWidgetsDTO,
  CreateAreaChartWidgetDto,
  CreateBubbleWidgetDto,
  CreateLineChartWidgetDto,
  CreateMatrixChartWidgetDto,
  CreatePolarAreaWidgetDto,
  CreatePunchcardWidgetDto,
  CreateRadarWidgetDto,
  CreateSankeyChartWidgetDto,
  CreateScatterplotWidgetDto,
  ExploreDataDTO,
  ICreateKpi,
  IDeleteKpi,
  IUpdateKpi,
  KpiItemsDTO,
  QueryGetKpis,
} from "./../../models/Widgets/index";
import { call, put, takeLatest } from "redux-saga/effects";
import { RequestCallbacks, ServerResponse } from "../../models";
import { PayloadAction } from "@reduxjs/toolkit";

import {
  createAreaChartWidget,
  createBarChartWidget,
  createBubbleChartWidget,
  createKpi,
  createLineChartWidget,
  createLollipopChartWidget,
  createMapChartWidget,
  createMapWidget,
  createMatrixChartWidget,
  createPolarAreaChartWidget,
  createPunchcardChartWidget,
  createRadarChartWidget,
  createSankeyChartWidget,
  createScatterplotChartWidget,
  deleteKpi,
  getAllKpis,
  getAllPublicWidgets,
  getAllWidgets,
  getExploreData,
  getPageBanner,
  getPageStories,
  getWidgetSuggestions,
  PageStoriesQuery,
  removeWidget,
  updateAreaChartWidget,
  updateBarChartWidget,
  updateBubbleChartWidget,
  updateKpi,
  updateLineChartWidget,
  updateLollipopChartWidget,
  updateMapWidget,
  updateMatrixChartWidget,
  updatePolarAreaChartWidget,
  updatePunchcardChartWidget,
  updateRadarChartWidget,
  updateSankeyChartWidget,
  updateScatterplotChartWidget,
  WidgetsSuggestionsQuery,
} from "../../api/widgets";
import {
  CreateBarWidgetDto,
  CreateLollipopChartWidgetDto,
  CreateMapWidgetDto,
  CreateWidgetDTO,
  CreateWidgetsDTO,
  QueryGetWidgets,
  UpdateSingleWidgetDTO,
  UpdateWidgetsDTO,
  WidgetsItemsDTO,
} from "../../models/Widgets";
import {
  setAiBannerImage,
  setAiKpis,
  setAiSuggestions,
  setExploreData,
  setStorytellingSuggestions,
} from "../slices/widgets";
import {
  setIsLoading,
  setKpiWidgets,
  setPageWidgets,
} from "../slices/projectPages";
import {
  getLineChartColors,
  getLineChartMarkers,
} from "../../components/Widgets/LineChart/utils/getLineChartMarkers";
import {
  getLollipopChartColors,
  getLollipopChartMarkers,
} from "../../components/Widgets/Lollipop/utils/getLollipopChartMarkers";
import {
  AreaChartDefaultMarkers,
  getAreaChartDefaultColors,
} from "../../components/Widgets/AreaChart/utils/getGroupData";
import { getBarChartFormatting } from "../../components/Widgets/GroupedBarChart/utils/getKeys";
import {
  createSection,
  deleteSection,
  getAllPublicSections,
  getAllSections,
  ICreateSection,
  ISection,
  IUpdateSection,
  QueryGetSections,
  updateSection,
} from "../../api/sections";
import {
  getScatterPlotDefaultColors,
  ScatterPlotDefaultMarkers,
} from "../../components/Widgets/ScatterPlot/utils/getGroupData";
import { getRadarChartDefaultColors } from "../../components/Widgets/RadarChart/utils/getGroupData";
import { getPolarAreaChartDefaultColors } from "../../components/Widgets/PolarAreaChart/utils/getGroupData";
import { getBubbleChartDefaultColors } from "../../components/Widgets/BubbleChart/utils/getGroupData";

export function* handleGetAllWidgets({
  payload,
}: PayloadAction<QueryGetWidgets>) {
  const { response }: ServerResponse = yield call(getAllWidgets, payload);
  if (response?.status === 200) {
    yield put(setPageWidgets({ items: [], count: 0 }));
    payload.callbacks?.onSuccess(response.data as WidgetsItemsDTO);
    yield put(setPageWidgets(response.data));
    yield put(setIsLoading(false));
  }
}
export function* handleGetAllPublicWidgets({
  payload,
}: PayloadAction<QueryGetWidgets>) {
  const { response }: ServerResponse = yield call(getAllPublicWidgets, payload);
  if (response?.status === 200) {
    yield put(setPageWidgets({ items: [], count: 0 }));
    payload.callbacks?.onSuccess(response.data as WidgetsItemsDTO);
    yield put(setPageWidgets(response.data));
    yield put(setIsLoading(false));
  }
}

export function* handleCreateMapWidget(
  action: PayloadAction<CreateMapWidgetDto>
) {
  yield call(createMapWidget, { ...action.payload, legend: false });
}

export function* handleCreateWidgets(action: PayloadAction<CreateWidgetsDTO>) {
  for (let barChart of action.payload.barChart) {
    yield call(createBarChartWidget, {
      ...barChart,
      orientation: barChart?.orientation || "vertical",
      formatting:
        barChart && barChart.formatting && barChart.formatting.length
          ? barChart.formatting
          : getBarChartFormatting(barChart),
      colors: [],
      legend: true,
    });
  }
  for (let lineChart of action.payload.lineChart) {
    yield call(createLineChartWidget, {
      ...lineChart,
      markers:
        lineChart.markers && lineChart.markers.length
          ? lineChart.markers
          : getLineChartMarkers(lineChart),
      formatting: getLineChartColors(lineChart),
      legend: true,
    });
  }
  for (let lollipopChart of action.payload.lollipopChart) {
    yield call(createLollipopChartWidget, {
      ...lollipopChart,
      orientation: lollipopChart?.orientation || "vertical",
      legend: true,
      markers:
        lollipopChart.markers && lollipopChart.markers.length
          ? lollipopChart.markers
          : getLollipopChartMarkers(lollipopChart),
      formatting:
        lollipopChart &&
        lollipopChart.formatting &&
        lollipopChart.formatting.length
          ? lollipopChart.formatting
          : getLollipopChartColors(lollipopChart),
    });
  }
  for (let sankeyChart of action.payload.sankeyChart) {
    yield call(createSankeyChartWidget, {
      ...sankeyChart,
      palette: {
        paletteId: "SequentialColors1",
        autoRange: true,
      },
      paletteId: "SequentialColors1",
    });
  }
  for (let mapChart of action.payload.mapChart) {
    yield call(createMapChartWidget, {
      ...mapChart,
      legend: false,
      paletteId: "SequentialColors1",
    });
  }
  for (let matrixChart of action.payload.matrixChart) {
    yield call(createMatrixChartWidget, {
      ...matrixChart,
      palette: {
        paletteId: "SequentialColors1",
        autoRange: true,
      },
      paletteId: "SequentialColors1",
    });
  }
  for (let areaChart of action.payload.areaChart) {
    yield call(createAreaChartWidget, {
      ...areaChart,
      legend: true,
      markers:
        areaChart.markers && areaChart.markers.length
          ? areaChart.markers
          : AreaChartDefaultMarkers(areaChart),
      formatting:
        areaChart && areaChart.formatting && areaChart.formatting.length
          ? areaChart.formatting
          : getAreaChartDefaultColors(areaChart),
    });
  }

  for (let polarAreaChart of action.payload.polarAreaChart) {
    yield call(createPolarAreaChartWidget, {
      ...polarAreaChart,
      orientation: polarAreaChart?.orientation || "vertical",
      formatting: polarAreaChart?.formatting?.length
        ? polarAreaChart.formatting
        : getPolarAreaChartDefaultColors(polarAreaChart),
      colors: [],
      legend: true,
    });
  }

  for (let scatterplotChart of action.payload.scatterplotChart) {
    yield call(createScatterplotChartWidget, {
      ...scatterplotChart,
      orientation: scatterplotChart?.orientation || "vertical",
      formatting: scatterplotChart?.formatting?.length
        ? scatterplotChart.formatting
        : getScatterPlotDefaultColors(scatterplotChart),
      markers: scatterplotChart?.markers?.length
        ? scatterplotChart.markers
        : ScatterPlotDefaultMarkers(scatterplotChart),
      colors: [],
      legend: true,
    });
  }

  for (let radarChart of action.payload.radarChart) {
    yield call(createRadarChartWidget, {
      ...radarChart,
      orientation: radarChart?.orientation || "vertical",
      formatting: radarChart?.formatting?.length
        ? radarChart.formatting
        : getRadarChartDefaultColors(radarChart),
      colors: [],
      legend: true,
    });
  }

  for (let bubbleChart of action.payload.bubbleChart) {
    yield call(createBubbleChartWidget, {
      ...bubbleChart,
      orientation: bubbleChart?.orientation || "vertical",
      formatting: bubbleChart?.formatting?.length
        ? bubbleChart.formatting
        : getBubbleChartDefaultColors(bubbleChart),
      colors: [],
      legend: true,
    });
  }

  for (let punchcardChart of action.payload.punchcardChart) {
    yield call(createPunchcardChartWidget, {
      ...punchcardChart,
      palette: {
        paletteId: "SequentialColors1",
        autoRange: true,
      },
      paletteId: "SequentialColors1",
    });
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);
}

export function* handleRequestWidgetSuggestions({
  payload,
}: PayloadAction<WidgetsSuggestionsQuery>) {
  const {
    projectId,
    includeData,
    pageId,
    withStorytelling,
    callbacks,
    withKpi,
  } = payload;

  const { response }: ServerResponse = yield call(getWidgetSuggestions, {
    projectId,
    pageId,
    includeData,
    withStorytelling,
    withKpi,
  });

  if (response?.status === 200) {
    yield put(setAiSuggestions(response.data.suggested_charts));
    yield put(setAiKpis(response.data.kpis));
    callbacks?.onSuccess(response.data);
  }
}

export function* handleRequestDeleteWidget({ payload }: PayloadAction<string>) {
  const { response }: ServerResponse = yield call(removeWidget, payload);

  if (response?.status === 200) {
  }
}
export function* handleUpdateWidget(
  action: PayloadAction<UpdateSingleWidgetDTO>
) {
  if (action.payload.chartType === "lollipopChart") {
    yield call(updateLollipopChartWidget, action.payload);
  }
  if (action.payload.chartType === "barChart") {
    yield call(updateBarChartWidget, action.payload);
  }
  if (action.payload.chartType === "lineChart") {
    yield call(updateLineChartWidget, action.payload);
  }
  if (action.payload.chartType === "sankeyChart") {
    yield call(updateSankeyChartWidget, action.payload);
  }
  if (action.payload.chartType === "sankey") {
    yield call(updateSankeyChartWidget, action.payload);
  }
  if (action.payload.chartType === "mapChart") {
    yield call(updateMapWidget, action.payload);
  }
  if (action.payload.chartType === "matrix") {
    yield call(updateMatrixChartWidget, action.payload);
  }
  if (action.payload.chartType === "areaChart") {
    yield call(updateAreaChartWidget, action.payload);
  }
  if (action.payload.chartType === "scatterplot") {
    yield call(updateScatterplotChartWidget, action.payload);
  }
  if (action.payload.chartType === "radar") {
    yield call(updateRadarChartWidget, action.payload);
  }
  if (action.payload.chartType === "bubbleChart") {
    yield call(updateBubbleChartWidget, action.payload);
  }
  if (action.payload.chartType === "punchcardChart") {
    yield call(updatePunchcardChartWidget, action.payload);
  }
  if (action.payload.chartType === "polarAreaChart") {
    yield call(updatePolarAreaChartWidget, action.payload);
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);

  action.payload.callbacks?.onSuccess();
}

export function* handleUpdateWidgets(action: PayloadAction<UpdateWidgetsDTO>) {
  for (let barChart of action.payload.barChart) {
    yield call(updateBarChartWidget, barChart);
  }
  for (let lineChart of action.payload.lineChart) {
    yield call(updateLineChartWidget, lineChart);
  }
  for (let lollipopChart of action.payload.lollipopChart) {
    yield call(updateLollipopChartWidget, lollipopChart);
  }
  for (let sankeyChart of action.payload.sankeyChart) {
    yield call(updateSankeyChartWidget, sankeyChart);
  }
  for (let mapChart of action.payload.mapChart) {
    yield call(updateMapWidget, mapChart);
  }
  for (let matrixChart of action.payload.matrixChart) {
    yield call(updateMatrixChartWidget, matrixChart);
  }
  for (let areaChart of action.payload.areaChart) {
    yield call(updateAreaChartWidget, areaChart);
  }
  for (let polarAreaChart of action.payload.polarAreaChart) {
    yield call(updatePolarAreaChartWidget, polarAreaChart);
  }
  for (let punchcardChart of action.payload.punchcardChart) {
    yield call(updatePunchcardChartWidget, punchcardChart);
  }
  for (let scatterplotChart of action.payload.scatterplotChart) {
    yield call(updateScatterplotChartWidget, scatterplotChart);
  }
  for (let radarChart of action.payload.radarChart) {
    yield call(updateRadarChartWidget, radarChart);
  }
  for (let chart of action.payload.bubbleChart) {
    yield call(updateBubbleChartWidget, chart);
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);
}

export function* handleRequestCreateWidgetSection(
  action: PayloadAction<CreateWidgetDTO>
) {
  if (action.payload.chartType === "lollipopChart") {
    yield call(
      createLollipopChartWidget,
      action.payload as CreateLollipopChartWidgetDto
    );
  }
  if (action.payload.chartType === "barChart") {
    yield call(createBarChartWidget, action.payload as CreateBarWidgetDto);
  }
  if (action.payload.chartType === "lineChart") {
    yield call(
      createLineChartWidget,
      action.payload as CreateLineChartWidgetDto
    );
  }
  if (action.payload.chartType === "sankeyChart") {
    yield call(
      createSankeyChartWidget,
      action.payload as CreateSankeyChartWidgetDto
    );
  }
  if (action.payload.chartType === "mapChart") {
    yield call(createMapWidget, {
      ...action.payload,
      legend: false,
    } as CreateMapWidgetDto);
  }
  if (action.payload.chartType === "matrix") {
    yield call(
      createMatrixChartWidget,
      action.payload as CreateMatrixChartWidgetDto
    );
  }
  if (action.payload.chartType === "areaChart") {
    yield call(
      createAreaChartWidget,
      action.payload as CreateAreaChartWidgetDto
    );
  }
  if (action.payload.chartType === "polarAreaChart") {
    yield call(
      createPolarAreaChartWidget,
      action.payload as CreatePolarAreaWidgetDto
    );
  }
  if (action.payload.chartType === "punchcardChart") {
    yield call(
      createPunchcardChartWidget,
      action.payload as CreatePunchcardWidgetDto
    );
  }
  if (action.payload.chartType === "radarChart") {
    yield call(createRadarChartWidget, action.payload as CreateRadarWidgetDto);
  }

  if (action.payload.chartType === "bubbleChart") {
    yield call(
      createBubbleChartWidget,
      action.payload as CreateBubbleWidgetDto
    );
  }

  if (action.payload.chartType === "scatterplotChart") {
    yield call(
      createScatterplotChartWidget,
      action.payload as unknown as CreateScatterplotWidgetDto
    );
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);

  setTimeout(() => {
    action.payload.callbacks?.onSuccess();
  }, 1000);
}

export function* handleRequestPageStories({
  payload,
}: PayloadAction<PageStoriesQuery>) {
  const { projectId, pageId, filter, callbacks } = payload;

  const { response }: ServerResponse = yield call(getPageStories, {
    projectId,
    pageId,
    filter,
  });

  if (response?.status === 200) {
    yield put(setStorytellingSuggestions(response.data));
    callbacks?.onSuccess(response.data);
  }
}

export function* handleRequestPageBanner({
  payload,
}: PayloadAction<PageStoriesQuery>) {
  const { projectId, pageId, filter, callbacks } = payload;

  const { response }: ServerResponse = yield call(getPageBanner, {
    projectId,
    pageId,
    filter,
  });

  if (response?.status === 200) {
    yield put(setAiBannerImage(response.data.banner_image));
    callbacks?.onSuccess(response.data);
  }
}

export function* handleRequestExploreData({
  payload,
}: PayloadAction<ExploreDataDTO>) {
  const { pageId, county } = payload;

  const { response }: ServerResponse = yield call(getExploreData, {
    pageId,
    county,
  });

  if (response?.status === 200) {
    yield put(setExploreData(response.data));
  }
}

export function* handleCreateAndUpdateWidgets(
  action: PayloadAction<CreateAndUpdateWidgetsDTO>
) {
  const { callbacks } = action.payload;
  for (let barChart of action.payload.barChart) {
    yield call(updateBarChartWidget, barChart);
  }
  for (let lineChart of action.payload.lineChart) {
    yield call(updateLineChartWidget, lineChart);
  }
  for (let lollipopChart of action.payload.lollipopChart) {
    yield call(updateLollipopChartWidget, lollipopChart);
  }
  for (let sankeyChart of action.payload.sankeyChart) {
    yield call(updateSankeyChartWidget, sankeyChart);
  }
  for (let mapChart of action.payload.mapChart) {
    yield call(updateMapWidget, mapChart);
  }
  for (let matrixChart of action.payload.matrixChart) {
    yield call(updateMatrixChartWidget, matrixChart);
  }
  for (let areaChart of action.payload.areaChart) {
    yield call(updateAreaChartWidget, areaChart);
  }
  for (let polarAreaChart of action.payload.polarAreaChart) {
    yield call(updatePolarAreaChartWidget, polarAreaChart);
  }
  for (let punchcardChart of action.payload.punchcardChart) {
    yield call(updatePunchcardChartWidget, punchcardChart);
  }
  for (let scatterplotChart of action.payload.scatterplotChart) {
    yield call(updateScatterplotChartWidget, scatterplotChart);
  }
  for (let radarChart of action.payload.radarChart) {
    yield call(updateRadarChartWidget, radarChart);
  }
  for (let chart of action.payload.bubbleChart) {
    yield call(updateBubbleChartWidget, chart);
  }

  for (let barChart of action.payload.createBarChart) {
    yield call(createBarChartWidget, {
      ...barChart,
      orientation: barChart?.orientation || "vertical",
      formatting: getBarChartFormatting(barChart),
      colors: [],
    });
  }
  for (let lineChart of action.payload.createLineChart) {
    yield call(createLineChartWidget, {
      ...lineChart,
      markers: getLineChartMarkers(lineChart),
      formatting: getLineChartColors(lineChart),
    });
  }
  for (let lollipopChart of action.payload.createLollipopChart) {
    yield call(createLollipopChartWidget, {
      ...lollipopChart,
      orientation: lollipopChart?.orientation || "vertical",
      markers: getLollipopChartMarkers(lollipopChart),
      formatting: getLollipopChartColors(lollipopChart),
    });
  }
  for (let sankeyChart of action.payload.createSankeyChart) {
    yield call(createSankeyChartWidget, {
      ...sankeyChart,
      palette: {
        paletteId: "SequentialColors1",
        autoRange: true,
      },
      paletteId: "SequentialColors1",
    });
  }
  for (let mapChart of action.payload.createMapChart) {
    yield call(createMapChartWidget, { ...mapChart, legend: false });
  }
  for (let matrixChart of action.payload.createMatrixChart) {
    yield call(createMatrixChartWidget, {
      ...matrixChart,
      palette: {
        paletteId: "SequentialColors1",
        autoRange: true,
      },
      paletteId: "SequentialColors1",
    });
  }
  for (let areaChart of action.payload.createAreaChart) {
    yield call(createAreaChartWidget, {
      ...areaChart,
      markers:
        areaChart.markers && areaChart.markers.length
          ? areaChart.markers
          : AreaChartDefaultMarkers(areaChart),
      formatting:
        areaChart && areaChart.formatting && areaChart.formatting.length
          ? areaChart.formatting
          : getAreaChartDefaultColors(areaChart),
    });
  }
  for (let scatterplotChart of action.payload.createScatterplotChart) {
    yield call(createScatterplotChartWidget, {
      ...scatterplotChart,
      orientation: scatterplotChart?.orientation || "vertical",
      formatting: scatterplotChart?.formatting?.length
        ? scatterplotChart.formatting
        : getScatterPlotDefaultColors(scatterplotChart),
      markers: scatterplotChart?.markers?.length
        ? scatterplotChart.markers
        : ScatterPlotDefaultMarkers(scatterplotChart),
      colors: [],
    });
  }
  for (let polarAreaChart of action.payload.createPolarAreaChart) {
    yield call(createPolarAreaChartWidget, {
      ...polarAreaChart,
      orientation: polarAreaChart?.orientation || "vertical",
      formatting: polarAreaChart?.formatting?.length
        ? polarAreaChart.formatting
        : getPolarAreaChartDefaultColors(polarAreaChart),
      colors: [],
    });
  }
  for (let radarChart of action.payload.createRadarChart) {
    yield call(createRadarChartWidget, {
      ...radarChart,
      orientation: radarChart?.orientation || "vertical",
      formatting: getRadarChartDefaultColors(radarChart),
      colors: [],
    });
  }

  for (let bubbleChart of action.payload.createBubbleChart) {
    yield call(createBubbleChartWidget, {
      ...bubbleChart,
      orientation: bubbleChart?.orientation || "vertical",
      formatting: bubbleChart?.formatting?.length
        ? bubbleChart.formatting
        : getBubbleChartDefaultColors(bubbleChart),
      colors: [],
    });
  }

  for (let punchcardChart of action.payload.createPunchcardChart) {
    yield call(createPunchcardChartWidget, {
      ...punchcardChart,
      palette: {
        paletteId: "SequentialColors1",
        autoRange: true,
      },
      paletteId: "SequentialColors1",
      colors: [],
    });
  }

  yield call(handleGetAllWidgets, {
    payload: {
      pageId: action.payload.pageId,
      includeData: true,
    },
  } as PayloadAction<{ pageId: string; includeData: boolean }>);
  callbacks?.onSuccess();
}

export function* handlePageSections({
  payload,
}: PayloadAction<QueryGetSections>) {
  const { response }: ServerResponse = yield call(getAllSections, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(
      response.data as { items: ISection[]; count: number }
    );
  }
}

export function* handlePublicPageSections({
  payload,
}: PayloadAction<QueryGetSections>) {
  const { response }: ServerResponse = yield call(getAllPublicSections, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(
      response.data as { items: ISection[]; count: number }
    );
  }
}

export function* handleCreatePageSections({
  payload,
}: PayloadAction<ICreateSection>) {
  const { response }: ServerResponse = yield call(createSection, payload);
  if (response?.status === 201) {
    payload.callbacks?.onSuccess(response.data as ISection);
  }
}

export function* handleUpdatePageSections({
  payload,
}: PayloadAction<IUpdateSection>) {
  const { response }: ServerResponse = yield call(updateSection, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data as ISection);
  }
}

export function* handleDeletePageSections({
  payload,
}: PayloadAction<{ id: string; callbacks?: RequestCallbacks<ISection> }>) {
  const { response }: ServerResponse = yield call(deleteSection, payload.id);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data as ISection);
  }
}

export function* handleGetAllKpis({ payload }: PayloadAction<QueryGetKpis>) {
  const { response }: ServerResponse = yield call(getAllKpis, payload);
  if (response?.status === 200) {
    yield put(setKpiWidgets(response.data));
    payload.callbacks?.onSuccess(response.data as KpiItemsDTO);
  }
}

export function* handleCreateKpis({ payload }: PayloadAction<ICreateKpi>) {
  const { response }: ServerResponse = yield call(createKpi, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data as AiKpiDto);
  }
}

export function* handleUpdateKpis({ payload }: PayloadAction<IUpdateKpi>) {
  const { response }: ServerResponse = yield call(updateKpi, payload);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess(response.data as AiKpiDto);
  }
}

export function* handleDeleteKpis({ payload }: PayloadAction<IDeleteKpi>) {
  const { response }: ServerResponse = yield call(deleteKpi, payload.id);
  if (response?.status === 200) {
    payload.callbacks?.onSuccess();
  }
}

export function* watchWidgetsSaga() {
  yield takeLatest("currentProject/requestPageWidgets", handleGetAllWidgets);
  yield takeLatest(
    "currentProject/requestPublicPageWidgets",
    handleGetAllPublicWidgets
  );
  yield takeLatest("widgets/requestCreateMapWidget", handleCreateMapWidget);
  yield takeLatest("widgets/requestCreateWidgets", handleCreateWidgets);
  yield takeLatest(
    "widgets/requestWidgetsSuggestions",
    handleRequestWidgetSuggestions
  );
  yield takeLatest(
    "widgets/requestCreateAndUpdateWidgets",
    handleCreateAndUpdateWidgets
  );
  yield takeLatest("widgets/requestPageStories", handleRequestPageStories);
  yield takeLatest("widgets/requestDeleteWidget", handleRequestDeleteWidget);
  yield takeLatest(
    "widgets/requestCreateWidgetSection",
    handleRequestCreateWidgetSection
  );
  yield takeLatest("widgets/requestUpdateWidgets", handleUpdateWidgets);
  yield takeLatest("widgets/requestUpdateWidget", handleUpdateWidget);
  yield takeLatest("currentProject/requestPageSections", handlePageSections);
  yield takeLatest("currentProject/requestPublicPageSections", handlePublicPageSections);
  yield takeLatest(
    "currentProject/requestCreatePageSections",
    handleCreatePageSections
  );
  yield takeLatest(
    "currentProject/requestUpdatePageSections",
    handleUpdatePageSections
  );
  yield takeLatest(
    "currentProject/requestDeletePageSections",
    handleDeletePageSections
  );
  yield takeLatest("widgets/requestPageBanner", handleRequestPageBanner);
  yield takeLatest("widgets/requestExploreData", handleRequestExploreData);
  yield takeLatest("currentProject/requestPageKpis", handleGetAllKpis);
  yield takeLatest("currentProject/requestCreateKpis", handleCreateKpis);
  yield takeLatest("currentProject/requestUpdateKpis", handleUpdateKpis);
  yield takeLatest("currentProject/requestDeleteKpis", handleDeleteKpis);
}
