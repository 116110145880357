import styled from "styled-components";

export const TooltipContainer = styled.div<{ $triangleLeft: string; $triangleTransform: string }>`
  position: absolute;
  background-color: white;
  color: ${({ theme }) => theme.neutralText};
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  padding: 8px;

  pointer-events: none;
  z-index: 1000;

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 8px solid white;
    top: 100%;
    left: ${({ $triangleLeft }) => $triangleLeft};
    transform: ${({ $triangleTransform }) => $triangleTransform} rotateX(180deg);
  }

  &::before {
    transform: ${({ $triangleTransform }) => $triangleTransform} rotateX(180deg);
    border-right: 7px solid transparent;
    border-left: 7px solid transparent;
    border-bottom: 10px solid black;
    position: absolute;
    opacity: 0.1;
    content: "";
    height: 0;
    left: ${({ $triangleLeft }) => $triangleLeft};
    top: 100%;
    width: 0;
  }
`;

export const TooltipBlock = styled.div`
  flex-direction: column;
  justify-content: start;
  align-items: start;
  min-width: 110px;
  min-height: 50px;
  display: flex;
`;

export const TooltipColumn = styled.div<{
  $border?: boolean;
}>`
  display: flex;
  width:100%;
  justify-content: space-between;
  flex-direction: row;
  gap: 2px;
  border-bottom: 1px dashed ${({$border, theme}) => $border ? theme.neutralBorder : 'transparent'};
  &:last-child {
    border: none;
  }
`;

export const TooltipHeading = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

`

export const TooltipLabel = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  margin: 2px 0;
  width: 60px;
  opacity: 0.6;
`;

export const TooltipValue = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin: 2px 0;
  width: max-content;
`;
