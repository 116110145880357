import React, { useEffect } from "react";
import { PageMenu } from "../../components/PageMenu";
import { HeadingMenuContainer } from "../../components/NavBar/styles";
import {
  ProfilePageWrapper,
  UserProfileHeaderIcon,
  UserProfileHeaderUserDescription,
  UserProfileHeaderUserDetails,
  UserProfileHeaderUserName,
  UserProfileHeaderUserRoleBadge,
  UserProfileHeaderWrapper,
} from "./styles";
import { ProfileSideBar } from "../../components/ProfileSideBar";
import { UserProfileProjects } from "../../components/UserProfileProjects";
import { useDispatch } from "react-redux";
import { requestProfile } from "../../store/slices/profiles";

export const ProfilePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(requestProfile());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <HeadingMenuContainer>
        <UserProfileHeaderWrapper>
          <UserProfileHeaderIcon />
          <UserProfileHeaderUserDetails>
            <UserProfileHeaderUserName>
              Robert Robertson
            </UserProfileHeaderUserName>
            <UserProfileHeaderUserDescription>
              Business analyst
              <UserProfileHeaderUserRoleBadge>
                Admin
              </UserProfileHeaderUserRoleBadge>
            </UserProfileHeaderUserDescription>
          </UserProfileHeaderUserDetails>
        </UserProfileHeaderWrapper>
        <PageMenu />
      </HeadingMenuContainer>
      <ProfilePageWrapper>
        <ProfileSideBar />
        <UserProfileProjects />
      </ProfilePageWrapper>
    </>
  );
};
