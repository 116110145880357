import React, { ChangeEvent } from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlock,
  FullNameBlockWrapper,
  SubTitleText,
  TitleText,
  TitleWrapper,
  SlashButton,
} from "../styles";
import { FormProfileGroup } from "../FormGroup";
import { FormikErrors, FormikTouched } from "formik";
import { ArrowRight } from "react-bootstrap-icons";
import { Slash } from "../../Share/styles";
import { IProfile } from "../../../../models/Widgets";

type SecurityProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  values: IProfile;
  errors: {
    [key: string]:
      | string
      | FormikErrors<any>
      | string[]
      | FormikErrors<any>[]
      | undefined;
  };
  touched: {
    [key: string]:
      | boolean
      | FormikTouched<any>
      | FormikTouched<any>[]
      | undefined;
  };
};

export const Security: React.FC<SecurityProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <TitleWrapper>
            <TitleText>Change your password</TitleText>
            <SubTitleText>
              When you change your password, we keep you logged in to this
              device but may log you out from your other devices.
            </SubTitleText>
          </TitleWrapper>
          <FullNameBlock>
            <FormProfileGroup
              id="currentPassword"
              type="text"
              name="currentPassword"
              errors={errors.currentPassword as string}
              placeholder="Current Password"
              values={"values.currentPassword REQ TO BACK"}
              handleChange={handleChange}
              touched={touched.currentPassword as boolean}
              handleBlur={handleBlur}
              required
            />

            <FormProfileGroup
              id="newPassword"
              type="text"
              name="newPassword"
              errors={errors.newPassword as string}
              placeholder="Enter New Password"
              values={"values.newPassword REQ TO BACK"}
              handleChange={handleChange}
              touched={touched.newPassword as boolean}
              handleBlur={handleBlur}
              required
            />
          </FullNameBlock>
          <TitleWrapper $mt={"16px"}>
            <TitleText>Two-factor verification</TitleText>
            <SubTitleText>
              Keep your account extra secure with a second login step.
            </SubTitleText>
            <SlashButton>
              <Slash />
              Manage two-step verification
              <ArrowRight />
              <Slash />
            </SlashButton>
          </TitleWrapper>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};
