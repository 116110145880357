import React, { useMemo, useRef, useState } from "react";
import { BarGroupHorizontal } from "@visx/shape";
import { Group } from "@visx/group";
import { scaleLinear, scaleBand, scaleOrdinal } from "@visx/scale";
import { AxisBottom, AxisLeft } from "@visx/axis";
import {
  DatavizRecommendedCount,
  DatavizSettingsIcon,
  HeaderWrapper,
  SettingsButtonWrapper,
  Title,
} from "../VerticalBarchart/styles";
import { setActiveModal } from "../../../store/slices/modals";
import { useDispatch, useSelector } from "react-redux";
import { getIsEditMode, getIsPublicMode } from "../../../store/selectors/main";
import useMeasure from "react-use-measure";
import {
  getCurrentWidget,
  getPageSettings,
} from "../../../store/selectors/projects";
import { ticksFormatter } from "../../../helpers/ticksFormatter";
import { calculateNumTicks } from "../widgetHelpers";
import { Tooltip } from "../Tooltip";
import { Loader } from "../../Loader";
import { AiSuggestionsDto, WidgetItem } from "../../../models/Widgets";
import { getAiSuggestions } from "../../../store/selectors/widgets";
import { setCurrentWidget } from "../../../store/slices/projectPages";
import { SelectBage } from "../SelectBage";
import { replaceWords } from "../../../helpers/replaceName";
import { getCurrentColor } from "../utils/getCurrentMarker";
import { AVAILABLE_WIDGETS } from "../../../constants/widgetRecomended";
import {
  getClientPosition,
  LabelTooltip,
  TickLabel,
} from "../components/LabelTooltip";
import { ChartLegend, ChartLegendValue } from "../../ChartLegend";
import { getBarChartKeys } from "../GroupedBarChart/utils/getKeys";
import { createPortal } from "react-dom";
import { Rect } from "./styles";
import { HeadingNameAndButton } from "../styles";
import { getActiveModal } from "../../../store/selectors/modals";

interface GroupedBarChartProps {
  storytelling?: boolean;
  recommended?: boolean;
  currentWidget: WidgetItem;
  showLegend?: boolean;
  selected?: boolean;
  hideName?: boolean;
  hideSettings?: boolean;
}

type DataItem = {
  year: string;
  [key: string]: string | number;
};

export const HorizontalGroupedBarChart = ({
  storytelling,
  recommended,
  currentWidget,
  showLegend = true,
  selected = false,
  hideName = false,
  hideSettings = false,
}: GroupedBarChartProps) => {
  const dispatch = useDispatch();

  const [ref, bounds] = useMeasure();
  const [refWidget, boundsWidget] = useMeasure();

  const isEditMode = useSelector(getIsEditMode);
  const isPublicRoute = useSelector(getIsPublicMode);
  const activeModal = useSelector(getActiveModal);
  const modalCurrentWidget = useSelector(getCurrentWidget);
  const { styleId, showTooltip } = useSelector(getPageSettings);
  const aiSuggestions = useSelector(getAiSuggestions);

  const barChartSuggestion = aiSuggestions
    ?.filter((chart: AiSuggestionsDto) => chart.chartType === "barChart")
    ?.at(0);
  const data: any = currentWidget?.data || [];
  const xAxe = currentWidget?.xAxe?.[0] || barChartSuggestion?.xAxe?.[0];
  const yAxe = currentWidget?.yAxe?.[0] || barChartSuggestion?.yAxe?.[0];
  const barchartData = currentWidget?.data;
  const groupBy = currentWidget?.groupBy?.at(0);

  const getYear = (d: DataItem) => d[xAxe];
  const getValue = (d: DataItem) => Number(d[yAxe]);

  const maxXAxe = Math.max(...data?.map(getValue));

  const roundedMaxXAxe = useMemo(
    () =>
      Math.ceil(
        maxXAxe / Math.pow(10, Number(Math.floor(Math.log10(maxXAxe))))
      ) * Math.pow(10, Number(Math.floor(Math.log10(maxXAxe)))),
    [maxXAxe]
  );

  const divRef = useRef<HTMLDivElement | null>(null);
  const [tooltip, setTooltip] = useState<{
    name?: string;
    data: { [key: string]: string };
    x: number;
    y: number;
  } | null>(null);
  const [labelTooltip, setLabelTooltip] = useState<{
    data: string;
    x: number;
    y: number;
  } | null>(null);

  const [hoveredElement, setHoveredElement] = useState<null | string>(null);

  const width = bounds.width || 1084;
  const height = bounds.height || 163;

  const uniqueValuesKeys =
    (currentWidget?.uniqueValues &&
      Object.keys(currentWidget?.uniqueValues!)) ||
    [];
  const groupByKey =
    groupBy && groupBy?.length ? groupBy : uniqueValuesKeys?.at(0);

  const keys =
    uniqueValuesKeys?.length && currentWidget?.uniqueValues
      ? currentWidget?.uniqueValues[groupByKey!]
      : getBarChartKeys(currentWidget);

  const legendValues: ChartLegendValue[] = keys?.map((key: string) => ({
    label: key,
    color: getCurrentColor(currentWidget, key, styleId),
  }));

  const yearsSet = new Set(barchartData?.map((item) => item.year)).size;
  const isLongValue = barchartData?.some((el) => el[xAxe].length > 6);
  const margin = {
    top: 10,
    right: 10,
    bottom: barchartData?.length! > 7 ? -4 : 40,
    left: isLongValue ? 80 : 40,
  };

  const numTicks = calculateNumTicks({ height: height });

  const name = useMemo(
    () =>
      recommended ? replaceWords(currentWidget?.name) : currentWidget?.name,
    [currentWidget?.name, recommended]
  );

  const refHeight = useMemo(
    () => divRef?.current?.parentElement?.clientHeight,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [divRef, divRef.current]
  );

  if (!barchartData || !barchartData.length) {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Loader blur={false} />
      </div>
    );
  }

  // const h2 = barchartData.length * (barHeight + 3);
  const numBarsInGroup = keys?.length;
  const fixedBarHeight = 16;
  const fixedBarSpacing = 1;
  const totalSpaceRequiredPerGroup =
    numBarsInGroup * fixedBarHeight + (numBarsInGroup - 1) * fixedBarSpacing;
  const yMax = totalSpaceRequiredPerGroup * yearsSet + 50 * yearsSet - 1;

  const formattedData = data?.reduce((acc: any[], item: any) => {
    const itemIndex = acc.findIndex((obj) => obj.year === item.year);
    if (itemIndex !== -1) {
      acc[itemIndex] = {
        ...acc[itemIndex],
        [item[groupBy!]]: item[yAxe],
      };
      return acc;
    }
    acc.push({
      year: item[xAxe],
      [item[groupBy!]]: item.value,
    });
    return acc;
  }, []);

  const yearScale = scaleBand<string>({
    domain: data?.map(getYear),
    align: 0.5,
    range: [0, yMax],
  });

  const keysScale = scaleBand<string>({
    domain: keys,
    range: [0, totalSpaceRequiredPerGroup],
    paddingInner: 0.1,
    paddingOuter: 0,
  });

  const valueScale = scaleLinear<number>({
    domain: [0, roundedMaxXAxe],
    range: [margin.left, width - margin.right],
    nice: true,
  });

  const colorScale = scaleOrdinal<string, string>({
    domain: keys,
    range: legendValues?.map((r) => r.color),
  });
  return (
    <>
      <HeaderWrapper ref={refWidget} style={{ marginBottom: "10px" }}>
        {!storytelling && (
          <HeadingNameAndButton>
            {!hideName ? <Title>{name}</Title> : <div />}
            {!hideSettings && !isPublicRoute && !recommended && isEditMode && !storytelling ? (
              <SettingsButtonWrapper
                $modalOpen={
                  !!activeModal?.length &&
                  modalCurrentWidget?.id === currentWidget?.id
                }
                onClick={() => {
                  dispatch(setCurrentWidget(currentWidget!));
                  dispatch(setActiveModal({ id: "recommendedWidgetsModal" }));
                }}
              >
                <DatavizRecommendedCount>
                  {AVAILABLE_WIDGETS["barChart"]?.length}
                </DatavizRecommendedCount>
                <DatavizSettingsIcon />
              </SettingsButtonWrapper>
            ) : null}
            {recommended ? <SelectBage selected={selected} /> : null}
          </HeadingNameAndButton>
        )}
        {currentWidget?.formatting?.length! > 1 && legendValues?.length > 1 && showLegend && currentWidget.legend && (
          <ChartLegend
            chartWidth={boundsWidget.width}
            legendType="unit"
            legendValues={legendValues}
          />
        )}
      </HeaderWrapper>
      <div
        ref={divRef}
        style={
          barchartData.length > 7
            ? {
              // height: refHeight ? refHeight - 50 * 2 : 160,
              height: refHeight
                ? refHeight - (44 + 12 + 12) < 170
                  ? 170
                  : refHeight - (44 + 12 + 12)
                : 170,
              overflowY: "auto",
            }
            : { height: "100%" }
        }
      >
        <svg width="100%" height={yMax} ref={ref}>
          <Group>
            {yearScale.domain().map((d, idx) => (
              <line
                key={idx}
                x1={margin.left}
                x2={width - margin.right}
                y1={yearScale(d)!}
                y2={yearScale(d)!}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            ))}
            {valueScale.ticks(numTicks).map((tick, idx) => (
              <line
                key={idx}
                x1={valueScale(tick)}
                x2={valueScale(tick)}
                y1={0}
                y2={height - margin.bottom}
                stroke="#ccc"
                strokeDasharray="1 2"
              />
            ))}
          </Group>
          {!(barchartData.length > 7) && (
            <AxisBottom
              scale={valueScale}
              top={height - margin.bottom}
              hideTicks
              numTicks={calculateNumTicks({ width })}
              tickFormat={(value: any) => ticksFormatter(value)}
              tickLabelProps={(_, index, values) => {
                const isFirstTick = index === 0;
                const isLastTick = index === values.length - 1;
                const textAnchor =
                  (isFirstTick && "start") || (isLastTick && "end") || "middle";
                return {
                  fontSize: 10,
                  fill: "#5F6877",
                  textAnchor,
                  dx: 0,
                };
              }}
              axisLineClassName="barchartAxisLine"
            />
          )}
          <AxisLeft
            scale={yearScale}
            top={0}
            hideAxisLine
            left={margin.left}
            tickLineProps={{ stroke: "#939BA7" }}
            numTicks={barchartData.length > 7 ? barchartData.length : numTicks}
            tickLabelProps={() => ({
              fontSize: 11,
              fill: "#5F6877",
              textAnchor: "start",
              dy: 0,
              dx: isLongValue ? -79 : -37,
            })}
            tickComponent={(props) => (
              <TickLabel
                {...props}
                setTooltip={setLabelTooltip}
                length={isLongValue ? 10 : 4}
                dy={4}
              />
            )}
          />
          <Group left={margin.left}>
            <BarGroupHorizontal
              data={formattedData}
              keys={keys}
              height={yMax}
              y0={(d) => d[xAxe]}
              y0Scale={yearScale}
              y1Scale={keysScale}
              xScale={valueScale}
              color={colorScale}
              width={0}
            >
              {(barGroups) => {
                // const groupSpace = barGroups.find((item) => item.y0)?.y0 ?? 0;

                return barGroups.map((barGroup) => (
                  <Group
                    key={`bar-group-${barGroup.index}-${barGroup.y0}`}
                    top={barGroup.y0}
                  >
                    {barGroup.bars.map((bar) => {
                      if (!bar?.value) {
                        return "";
                      }
                      const legend = legendValues.find(
                        (r) => r.label === bar.key
                      );
                      const key = `bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}`;
                      // const y =
                      //   groupSpace / 2 -
                      //   8 +
                      //   getBarPosition(16, bar.index, keys.length);
                      return (
                        <Rect
                          key={key}
                          x={bar.x}
                          y={bar.y + bar.height * 1.55}
                          width={bar.width - valueScale(0)}
                          height={bar.height}
                          fill={legend?.color}
                          opacity={
                            hoveredElement
                              ? hoveredElement === key
                                ? 1
                                : 0.3
                              : 1
                          }
                          onMouseMove={(e) => {
                            const { x, y } = getClientPosition(e);
                            const hoveredBarGroup =
                              formattedData[barGroup.index];
                            if (
                              (showTooltip || currentWidget.tooltip) &&
                              !recommended
                            ) {
                              setHoveredElement(key);
                              setTooltip({
                                name: bar.key,
                                data: {
                                  year: hoveredBarGroup[xAxe],
                                  [groupBy!]: keys[bar.index],
                                  value: bar?.value?.toString(),
                                },
                                x: x - 27,
                                y: y - 85,
                              });
                            }
                          }}
                          onMouseLeave={() => {
                            setTooltip(null);
                            setHoveredElement(null);
                          }}
                        />
                      );
                    })}
                  </Group>
                ));
              }}
            </BarGroupHorizontal>
          </Group>
        </svg>
      </div>
      {barchartData.length > 7 && (
        <svg height={30} width={"100%"}>
          <AxisBottom
            scale={valueScale}
            hideTicks
            top={1}
            numTicks={calculateNumTicks({ width })}
            tickFormat={(value: any) => ticksFormatter(value)}
            tickLabelProps={(_, index, values) => {
              const isFirstTick = index === 0;
              const isLastTick = index === values.length - 1;
              const textAnchor =
                (isFirstTick && "start") || (isLastTick && "end") || "middle";
              return {
                fontSize: 10,
                fill: "#5F6877",
                textAnchor,
                dx: 0,
                dy: -1,
              };
            }}
            axisLineClassName="barchartAxisLine"
          />
        </svg>
      )}
      {tooltip &&
        createPortal(
          <Tooltip
            x={tooltip.x}
            y={tooltip.y}
            xAxe={xAxe}
            yAxe={yAxe}
            data={tooltip.data}
            name={tooltip.name}
          />,
          document.body
        )}
      {labelTooltip && (
        <LabelTooltip
          x={labelTooltip?.x}
          y={labelTooltip?.y}
          data={labelTooltip?.data}
        />
      )}
    </>
  );
};
