import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAllDatasets } from "../../store/selectors/datasets";
import { DatasetWrapper, ImportedFilesContainer } from "../NewProject/styles";
import { requestGetAllDatasets } from "../../store/slices/datasets";
import { DatasetModalListItem } from "../DatasetModalListItem";
import { FileLibraryItem } from "../../models/Files";

type DatasetLibraryType = {
  setDimensionsSelected: Dispatch<SetStateAction<any>>;
  setFileOverviewOpen: Dispatch<SetStateAction<boolean>>;
  searchInput: string;
  libraryDatasetsIds: string[];
  libraryDatasetOverviewOpen?: boolean;
};

interface IFilesWithSelectedProp extends FileLibraryItem {
  checkboxState: "checked" | "indeterminate" | "none";
}

export const DatasetLibraryList = ({
  setFileOverviewOpen,
  setDimensionsSelected,
  searchInput,
  libraryDatasetsIds,
  libraryDatasetOverviewOpen
}: DatasetLibraryType) => {
  const dispatch = useDispatch();
  const { items: datasets } = useSelector(getAllDatasets);
  const [dataSetList, setDataSetList] = useState<IFilesWithSelectedProp[]>(
    datasets.map((item) => ({ ...item, checkboxState: libraryDatasetsIds.find(el => (el === item.id || el === item.sourceId)) ? "checked" : "none" }))
  );
  useEffect(() => {
    setDataSetList(
      datasets.map((item) => ({ ...item, checkboxState: libraryDatasetsIds.find(el => (el === item.id || el === item.sourceId)) ? "checked" : "none" }))
    );
  }, [datasets, libraryDatasetsIds]);

  const filteredDatasets = dataSetList
    .filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    )
    // @ts-ignore
    .sort((a, b) => new Date(b?.createdAt) - new Date(a?.createdAt));

  useEffect(() => {
    dispatch(requestGetAllDatasets({}));
  }, [dispatch]);

  return (
    <DatasetWrapper $full>
      <ImportedFilesContainer $noGap>
        {filteredDatasets.map((dataSet) => (
          <DatasetModalListItem
            file={dataSet}
            key={dataSet.id + dataSet.name}
            setFileOverviewOpen={setFileOverviewOpen}
            isChecked={dataSet.checkboxState}
            libraryDatasetOverviewOpen={libraryDatasetOverviewOpen}
            onChange={(value: "checked" | "indeterminate" | "none") => {
              setDataSetList((prev) =>
                prev.map((prevItem) =>
                  prevItem.id === dataSet.id
                    ? { ...prevItem, checkboxState: value }
                    : prevItem
                )
              );
            }}
            setDimensionsSelected={setDimensionsSelected}
          />
        ))}
      </ImportedFilesContainer>
    </DatasetWrapper>
  );
};
