import React, { ChangeEvent } from "react";
import {
  EssentialsBlock,
  EssentialsWrapper,
  FullNameBlockWrapper,
  InputBlock,
  InputBlockFixed,
  ProfileLabel,
} from "../styles";
import { FormProfileGroup } from "../FormGroup";
import { FormikErrors, FormikTouched } from "formik";
import { IProfile } from "../../../../models/Widgets";

type ContactDetailsProps = {
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: React.ChangeEventHandler;
  values: IProfile;
  errors: {
    [key: string]:
      | string
      | FormikErrors<any>
      | string[]
      | FormikErrors<any>[]
      | undefined;
  };
  touched: {
    [key: string]:
      | boolean
      | FormikTouched<any>
      | FormikTouched<any>[]
      | undefined;
  };
};

export const ContactDetails: React.FC<ContactDetailsProps> = ({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
}) => {
  return (
    <EssentialsBlock>
      <EssentialsWrapper>
        <FullNameBlockWrapper>
          <ProfileLabel>Email</ProfileLabel>
          <FormProfileGroup
            id="email"
            type="text"
            name="email"
            errors={errors.email as string}
            placeholder="Email"
            values={values.email}
            handleChange={handleChange}
            touched={touched.email as boolean}
            handleBlur={handleBlur}
          />
        </FullNameBlockWrapper>
        <FullNameBlockWrapper>
          <ProfileLabel>Office Phone</ProfileLabel>
          <InputBlock>
            <FormProfileGroup
              id="officePhone"
              type="text"
              name="officePhone"
              errors={errors.officePhone as string}
              placeholder="Add number"
              values={values.officePhone}
              handleChange={handleChange}
              touched={touched.officePhone as boolean}
              handleBlur={handleBlur}
            />
            <InputBlockFixed>
              <FormProfileGroup
                id="officePhoneCode"
                type="text"
                name="officePhoneCode"
                errors={errors.officePhoneCode as string}
                placeholder="ext."
                values={"REQ TO BACK"}
                handleChange={handleChange}
                touched={touched.officePhoneCode as boolean}
                handleBlur={handleBlur}
              />
            </InputBlockFixed>
          </InputBlock>
        </FullNameBlockWrapper>
        <FullNameBlockWrapper>
          <ProfileLabel>Cell Phone</ProfileLabel>
          <InputBlock>
            <FormProfileGroup
              id="phoneNumber"
              type="text"
              name="phoneNumber"
              errors={errors.phoneNumber as string}
              placeholder="Add number"
              values={values.phoneNumber}
              handleChange={handleChange}
              touched={touched.phoneNumber as boolean}
              handleBlur={handleBlur}
            />
            <InputBlockFixed>
              <FormProfileGroup
                id="cellPhoneCode"
                type="text"
                name="cellPhoneCode"
                errors={errors.cellPhoneCode as string}
                placeholder="ext."
                values={"REQ TO BACK"}
                handleChange={handleChange}
                touched={touched.cellPhoneCode as boolean}
                handleBlur={handleBlur}
              />
            </InputBlockFixed>
          </InputBlock>
        </FullNameBlockWrapper>
      </EssentialsWrapper>
    </EssentialsBlock>
  );
};
